import axios from "axios"
import { API } from "../contants"
import { notify } from "react-notify-toast"
import { useMemo } from "react"

async function get() {
  try {
    const options = {
      method: "GET",
      url: API + `/api/v1/products`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      crossDomain: true,
    }

    const response = await axios.request(options)
    const products = response.data.products

    return products
  } catch (error) {
    const error_msg = "Error while listing products"
    notify.show(error_msg, "error")
    console.log(error)
    throw new Error(error_msg)
  }
}

export default function useGetProducts() {
  return useMemo(() => get, [])
}
