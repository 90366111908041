import React, { useState, useEffect, useCallback, useMemo } from "react"

const useStickyState = (defaultValue, key) => {
  const [value, setValue] = useState(() => {
    const stickyValue = window.localStorage.getItem(key)
    return stickyValue !== null ? JSON.parse(stickyValue) : defaultValue
  })
  useEffect(() => {
    window.localStorage.setItem(key, JSON.stringify(value))
  }, [key, value])

  const resetValue = useCallback(() => {
    setValue(defaultValue)
  }, [defaultValue])

  return useMemo(() => [value, setValue, resetValue], [resetValue, value])
}

export default useStickyState
