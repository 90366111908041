// @ts-nocheck
import React, { useEffect, useRef, useState } from "react"
import { TrashIcon, LinkIcon } from "../icons"

const VideoPlayer = ({
  src,
  rSrc,
  disabled,
  onMerge = () => {},
  onLoop,
  loopCounter,
  onDelete,
  isLastItem,
  isMerged = false,
  disableLinking,
  disableLooping,
  disableLoopPlus,
}) => {
  const videoRef = useRef()
  const [canPause, setCanPause] = useState(false)
  const [videoStatus, setVideoStatus] = useState("pause")
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    videoRef.current.src = `${src}#t=0.1`
  }, [src])

  useEffect(() => {
    if (!videoRef.current || isLoading || disabled) return

    const isPaused = videoRef.current.paused

    if (isPaused && videoStatus === "play") {
      videoRef.current.play().then(() => {
        setCanPause(true)
      })
    } else if (!isPaused && videoStatus === "pause" && canPause) {
      videoRef.current.currentTime = 0
      videoRef.current.pause()
    }
  }, [canPause, disabled, isLoading, videoStatus])

  return (
    <div
      className={`${
        isMerged && !isLoading ? "bg-[#00ff494a]" : ""
      } pr-6 last:pr-0 align-middle inline-block ${
        isLoading || disableLinking ? "" : "border-y-2"
      }`}
    >
      <div className="relative w-32">
        {!isMerged && !isLoading && !disableLooping && (
          <div className="absolute top-0 right-1/2 translate-x-1/2 text-white bg-black text-sm h-7 text-center p-0 m-0 hover:cursor-pointer z-10">
            <button
              onClick={(e) => {
                if (isLoading) return

                onLoop(loopCounter - 1)
                e.preventDefault()
                e.stopPropagation()
              }}
              disabled={isLoading}
              className="w-6 px-2 h-full mr-1"
            >
              -
            </button>
            <span>{loopCounter}</span>
            <button
              onClick={(e) => {
                if (isLoading || disableLoopPlus) return

                onLoop(loopCounter + 1)
                e.preventDefault()
                e.stopPropagation()
              }}
              disabled={isLoading || disableLoopPlus}
              className="w-6 px-2 h-full ml-1 disabled:opacity-30"
            >
              +
            </button>
          </div>
        )}
        {!isMerged && (
          <button
            onClick={(e) => {
              // if (isLoading) return

              onDelete()
              e.preventDefault()
              e.stopPropagation()
            }}
            // disabled={isLoading}
            className="absolute top-0 right-0 text-white bg-red-600 text-sm w-7 h-7 text-center p-0 m-0 hover:cursor-pointer z-10 disabled:opacity-0"
          >
            <TrashIcon className="w-5 h-5 ml-1" aria-hidden="true" />
          </button>
        )}
        {!isLoading && !isLastItem && !disableLinking && (
          <button
            onClick={(e) => {
              if (isLoading) return

              onMerge()
              e.preventDefault()
              e.stopPropagation()
            }}
            disabled={isLoading}
            className={`${
              isMerged
                ? "text-green-500 hover:opacity-75 hover:scale-90"
                : "scale-90 hover:text-green-500 hover:scale-100 text-gray-600 opacity-50"
            } transition-all absolute top-1/2 -translate-y-1/2 translate-x-[calc(50%+12px)] right-0 w-14 h-14 text-center p-0 m-0 hover:cursor-pointer z-10 disabled:opacity-0`}
          >
            <LinkIcon className="ml-1 " aria-hidden="true" />
          </button>
        )}
        <video
          loop
          muted
          controls={false}
          ref={videoRef}
          poster={"/gifs/loading.gif"}
          className="h-full"
          webkit-playsinline="true"
          playsInline
          onMouseOver={() => {
            !disabled && setVideoStatus("play")
          }}
          onTouchStart={() => {
            !disabled && setVideoStatus("play")
          }}
          onMouseOut={() => {
            !disabled && setVideoStatus("pause")
          }}
          onTouchEnd={() => {
            !disabled && setVideoStatus("pause")
          }}
          onCanPlay={(e) => {
            setIsLoading(false)
          }}
        />
      </div>
    </div>
  )
}

export default VideoPlayer
