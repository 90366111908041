import axios from "axios"
import { API } from "../contants"
import { notify } from "react-notify-toast"
import { useMemo } from "react"

async function create({ data }) {
  try {
    const options = {
      method: "POST",
      url: API + `/api/v1/product`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      crossDomain: true,
      data,
    }

    const response = await axios.request(options)
    const success = response.data.success

    return { success }
  } catch (error) {
    const error_msg = "Error while creating product"
    notify.show(error_msg, "error")
    console.log(error)
    throw new Error(error_msg)
  }
}

export default function useCreateProduct() {
  return useMemo(() => create, [])
}
