import React from "react"
import { BrowserRouter as Router, Route, Routes } from "react-router-dom"
import UserWrapper from "./containers/UserWrapper"
import Dashboard from "./pages/okgad/Dashboard"
import Login from "./pages/Login"
import ElevenlabsAdd from "./pages/ElevenlabsAdd"
import HotjarWrapper from "./containers/HotjarWrapper"
import { AudioProvider } from "./providers/AudioContext"

export const App = () => {
  return (
    <Router>
      <div className={`flex h-screen bg-gray-50 dark:bg-gray-900`}>
        <div className="flex flex-col flex-1 w-full">
          <main className="h-full overflow-y-auto">
            <div className="w-screen grid px-[6vw] py-[10vh] mx-auto">
              <Routes>
                <Route
                  path="/dashboard"
                  element={
                    <HotjarWrapper>
                      <UserWrapper>
                        <AudioProvider>
                          <Dashboard />
                        </AudioProvider>
                      </UserWrapper>
                    </HotjarWrapper>
                  }
                />
                <Route
                  path="/elevenlabs/add"
                  element={
                    <UserWrapper>
                      <ElevenlabsAdd />
                    </UserWrapper>
                  }
                />
                <Route
                  path="/login"
                  element={
                    <UserWrapper hiddenHeader refetchUserOnWindowFocus={false}>
                      <Login />
                    </UserWrapper>
                  }
                />
                <Route
                  path="/"
                  element={
                    <UserWrapper hiddenHeader refetchUserOnWindowFocus={false}>
                      <Login />
                    </UserWrapper>
                  }
                />
              </Routes>
            </div>
          </main>
        </div>
      </div>
    </Router>
  )
}
