import { useMutation } from "react-query"
import axios from "axios"
import { API } from "../contants"
import { notify } from "react-notify-toast"

async function login({ email, password }) {
  try {
    const options = {
      method: "POST",
      url: API + "/api/v1/login",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
      withCredentials: true,
      crossDomain: true,
      data: { password, email },
    }

    const response = await axios.request(options)

    const token = response.data.access_token

    notify.show("Loged in!", "success")

    localStorage.setItem("token", token)
    return token
  } catch (error) {
    const error_msg = "Login failed. Please check your credentials."
    notify.show(error_msg, "error")
    throw new Error(error_msg)
  }
}

export function useLoginMutation() {
  return useMutation("login", login)
}
