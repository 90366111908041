import React, { useState, useEffect, useCallback, useMemo } from "react"
import {
  Button,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "@roketid/windmill-react-ui"
// import response from "../data"
import useStickyState from "../../hooks/useStickyState"
import {
  TableBody,
  TableContainer,
  Table,
  TableHeader,
  TableCell,
  TableRow,
  Badge,
} from "@roketid/windmill-react-ui"
import AddForm from "../../containers/AddForm"
import useGetProducts from "../../mutations/useGetProducts"
import useGetCode from "../../queries/useGetCode"
import useGetUser from "../../queries/useGetUser"
import ReactPlayer from "react-player"
import useApprove from "../../queries/useApprove"
import delay from "../../functions/delay"
import { ResetAudioButton } from "../../components/ResetAudioButton"
import { ResetVideoButton } from "../../components/ResetVideoButton"
import { API } from "../../contants"

const statusTypeMap = {
  posted: "primary",
  approved: "success",
  finished: "warning",
  processing: "neutral",
  new: "danger",
}

const statusMap = {
  all: "All",
  posted: "Posted",
  approved: "Approved",
  finished: "Finished",
  processing: "Processing",
  new: "New",
}

export default function Dashboard() {
  const { data: profileData } = useGetUser()
  const isBasic = !!(profileData && profileData.role === "basic")
  const email = profileData && profileData.email || ''

  const getProducts = useGetProducts()

  const [selectedVideoCode, setCode] = useState("")
  const [selectedVideoLang, setLang] = useState("")
  const [selectedVideoStatus, setStatus] = useState("")
  const videoUrl =
    selectedVideoCode &&
    selectedVideoLang &&
    `${API}/cdn/v3/products/${selectedVideoCode}/${selectedVideoLang}`

  const [selectedView, setSelectedView] = useStickyState(
    "all",
    "dashboard-selected-view"
  )
  const [unfilteredData, setData] = useState([{}])
  const data = useMemo(() => {
    return selectedView === "all"
      ? isBasic && email ? unfilteredData.filter(({ status, email: creatorEmail }) => {
        return email === creatorEmail
      }) : unfilteredData
      : unfilteredData.filter(({ status, email: creatorEmail }) => {
        return status === selectedView && (!isBasic || email === creatorEmail)
      })
  }, [selectedView, unfilteredData, isBasic])

  const { isFetching, refetch: approveVideo } = useApprove(
    selectedVideoCode,
    selectedVideoLang
  )

  const [isAdding, setIsAdding] = useStickyState(false, "dashboard-is-adding")

  const [isUpadting, setIsUpdating] = useState(false)
  const updateProducts = useCallback(() => {
    setIsUpdating(true)
    getProducts().then((response) => {
      const rResponse = [...response]
      rResponse.reverse()
      setData(rResponse)
      setIsUpdating(false)
    })
  }, [getProducts])

  useEffect(() => {
    updateProducts()

    const intervalId = setInterval(() => {
      updateProducts()
    }, 5000)

    return () => clearInterval(intervalId)
  }, [updateProducts])

  // useEffect(() => {
  //   updateProducts()
  // }, [updateProducts])

  const closeModal = () => {
    updateProducts()
    setCode("")
    setLang("")
    setStatus("")
  }

  return (
    <section className="dashboard-section">
      <div className="grid gap-6 mb-8 md:grid-cols-2 xl:grid-cols-2">
        {/* {} */}
        <div className="flex flex-row gap-x-2 gap-y-2 max-w-[88vw] overflow-auto py-3">
          <Label radio>
            <Input
              crossOrigin="use-credentials"
              disabled={isAdding}
              type="radio"
              value="all"
              name="accountType"
              checked={selectedView === "all"}
              onChange={(e) => {
                setSelectedView(e.target.value)
              }}
            />
            <span className="ml-2">{statusMap.all}</span>
          </Label>
          <Label radio>
            <Input
              crossOrigin="use-credentials"
              disabled={isAdding}
              type="radio"
              value="approved"
              name="accountType"
              checked={selectedView === "approved"}
              onChange={(e) => {
                setSelectedView(e.target.value)
              }}
            />
            <span className="ml-2">{statusMap.approved}</span>
          </Label>
          <Label radio>
            <Input
              crossOrigin="use-credentials"
              disabled={isAdding}
              type="radio"
              value="finished"
              name="accountType"
              checked={selectedView === "finished"}
              onChange={(e) => {
                setSelectedView(e.target.value)
              }}
            />
            <span className="ml-2">{statusMap.finished}</span>
          </Label>
          <Label className="" radio>
            <Input
              crossOrigin="use-credentials"
              disabled={isAdding}
              type="radio"
              value="processing"
              name="accountType"
              checked={selectedView === "processing"}
              onChange={(e) => {
                setSelectedView(e.target.value)
              }}
            />
            <span className="ml-2">{statusMap.processing}</span>
          </Label>
          <Label className="" radio>
            <Input
              crossOrigin="use-credentials"
              disabled={isAdding}
              type="radio"
              value="new"
              name="accountType"
              checked={selectedView === "new"}
              onChange={(e) => {
                setSelectedView(e.target.value)
              }}
            />
            <span className="ml-2">{statusMap.new}</span>
          </Label>
        </div>
        <Button
          size="small"
          layout="primary"
          onClick={() => {
            updateProducts()
            setIsAdding((prev) => !prev)
          }}
          // disabled={!code}
          className="bg-[#383838] hover:bg-[#676767] text-white hover:text-white"
        >
          {isAdding ? "Cancel" : "Add +"}
        </Button>
      </div>
      {isAdding ? (
        <AddForm />
      ) : (
        <TableContainer className="">
          <Table>
            <TableHeader>
              <tr>
                <TableCell>Review</TableCell>
                <TableCell>Title</TableCell>
                <TableCell>Proggress</TableCell>
                <TableCell>Reset Video</TableCell>
                {!isBasic && <TableCell>Reset Audio</TableCell>}
                <TableCell>Code</TableCell>
                <TableCell>Lang</TableCell>
                <TableCell>Duration</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Created at</TableCell>
                <TableCell>Updated at</TableCell>
              </tr>
            </TableHeader>
            <TableBody>
              {!!data.length &&
                !!data[0].code &&
                data.map(
                  (
                    {
                      code,
                      title,
                      progress,
                      lang,
                      duration,
                      status,
                      created_at,
                      audios,
                      updated_at,
                      _id,
                      voice,
                      sentence,
                    },
                    i
                  ) => {
                    const hasDuration = duration && duration !== "-"
                    const audioDuration = audios ? audios.reduce(
                      (
                        p,
                        {
                          audio: {
                            audio_info: { length },
                          },
                        }
                      ) => length + p,
                      0
                    ) : ''
                    const isApproved = status === "approved"
                    const isPosted = status === "posted"
                    const isFinished = status === "finished"
                    const isProcessing = status === "processing"
                    const isNew = status === "new"
                    return (
                      <TableRow key={`${i}_${code}`}>
                        <TableCell>
                          <div className="flex items-center text-sm">
                            <button
                              disabled={isNew || isProcessing}
                              onClick={() => {
                                setCode(code)
                                setLang(lang)
                                setStatus(status)
                                // setVideoUrl(
                                //   `https://api.willian.it/cdn/v3/products/${code}/${lang}`
                                // )
                              }}
                              className={`text-xs disabled:opacity-40 text-white dark:text-gray-50 px-2 ${isPosted
                                ? "bg-blue-500 hover:bg-blue-700"
                                : isApproved
                                  ? "bg-green-500 hover:bg-green-700"
                                  : "bg-indigo-500 hover:bg-indigo-700"
                                } rounded-full`}
                            >
                              {isPosted
                                ? "Posted!"
                                : isApproved
                                  ? "Approved!"
                                  : "Review"}
                            </button>
                          </div>
                        </TableCell>
                        <TableCell>
                          <div className="flex items-center text-sm">
                            <p className="text-xs text-gray-600 dark:text-gray-400">
                              {title}
                            </p>
                          </div>
                        </TableCell>
                        <TableCell>
                          <div className="flex items-center text-sm">
                            <p className="text-xs text-gray-600 dark:text-gray-400">
                              {progress || 0}%
                            </p>
                          </div>
                        </TableCell>
                        <TableCell>
                          <ResetVideoButton
                            _id={_id}
                            code={code}
                            lang={lang}
                            voice={voice}
                          />
                        </TableCell>
                        {!isBasic && <TableCell>
                          <ResetAudioButton
                            _id={_id}
                            sentence={sentence}
                            lang={lang}
                            voice={voice}
                          />
                        </TableCell>}
                        <TableCell>
                          <div className="flex items-center text-sm">
                            <p className="text-xs text-gray-600 dark:text-gray-400">
                              {code}
                            </p>
                          </div>
                        </TableCell>
                        <TableCell>
                          <div className="flex items-center text-sm">
                            <p className="font-semibold">{lang}</p>
                          </div>
                        </TableCell>
                        <TableCell>
                          <span className="text-sm">
                            {hasDuration
                              ? Number(duration).toFixed(2)
                              : Number(audioDuration).toFixed(2)}
                            s
                          </span>
                        </TableCell>
                        <TableCell>
                          <Badge type={statusTypeMap[status]}>
                            {statusMap[status]}
                          </Badge>
                        </TableCell>
                        <TableCell>
                          <span className="text-sm">
                            {new Date(created_at).toLocaleDateString()}
                          </span>
                        </TableCell>
                        <TableCell>
                          <span className="text-sm">
                            {new Date(updated_at).toLocaleDateString()}
                          </span>
                        </TableCell>
                      </TableRow>
                    )
                  }
                )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <Modal isOpen={!!videoUrl} onClose={closeModal}>
        <ModalHeader>Review Video</ModalHeader>
        <ModalBody className="flex">
          <ReactPlayer
            controls
            light
            style={{
              height: "100%",
              width: "auto",
            }}
            stopOnUnmount
            playing={!!videoUrl}
            // url="https://api.willian.it/cdn/v3/products/SUQ/en"
            url={videoUrl}
          />
        </ModalBody>
        {selectedVideoStatus == "finished" && (
          <ModalFooter>
            <div className="hidden sm:block">
              <Button layout="outline" onClick={closeModal}>
                Cancel
              </Button>
            </div>
            {
              !isBasic && <div className="hidden sm:block">
                <Button
                  disabled={isFetching}
                  onClick={() => {
                    approveVideo()
                    delay(500).then(() => {
                      updateProducts()
                      closeModal()
                    })
                  }}
                >
                  Approve!
                </Button>
              </div>
            }
            <div className="block w-full sm:hidden">
              <Button block size="large" layout="outline" onClick={closeModal}>
                Cancel
              </Button>
            </div>
            {
              !isBasic && <div className="block w-full sm:hidden">
                <Button
                  disabled={isFetching}
                  onClick={() => {
                    approveVideo()
                    delay(500).then(() => {
                      updateProducts()
                      closeModal()
                    })
                  }}
                  block
                  size="large"
                >
                  Approve!
                </Button>
              </div>
            }
          </ModalFooter>
        )}
      </Modal>
    </section>
  )
}
