import React, { useState, useContext } from "react"
import {
  Avatar,
  Dropdown,
  DropdownItem,
  WindmillContext,
} from "@roketid/windmill-react-ui"
import { useNavigate } from "react-router-dom"
import { notify } from "react-notify-toast"
import {
  InterrogationIcon,
  MoonIcon,
  OutlineLogoutIcon,
  SunIcon,
  TrashIcon,
  AudioIcon,
} from "../icons"
import delay from "../functions/delay"
import useGetUser from "../queries/useGetUser"

function Header({ avatar, loadingAvatar }) {
  const { data: profile } = useGetUser()

  const navigate = useNavigate()
  const [areYouSure, setAreYouSure] = useState(false)
  const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false)
  const { mode, toggleMode } = useContext(WindmillContext)

  return (
    <>
      <header className="absolute w-full min-h-16 top-0 left-0 z-40 py-1 bg-white shadow-bottom dark:bg-gray-800 h-16">
        <div className="w-screen flex items-center justify-end h-full px-[6vw] mx-auto text-purple-600 dark:text-purple-300">
          <ul className="flex items-center flex-shrink-0 space-x-6">
            <li className="flex">
              <button
                className="rounded-md focus:outline-none focus:shadow-outline-purple"
                onClick={toggleMode}
                aria-label="Toggle color mode"
              >
                {mode === "dark" ? (
                  <SunIcon className="w-5 h-5" aria-hidden="true" />
                ) : (
                  <MoonIcon className="w-5 h-5" aria-hidden="true" />
                )}
              </button>
            </li>
            <li className="relative">
              <button
                disabled={loadingAvatar}
                className="rounded-full focus:shadow-outline-purple focus:outline-none disabled:opacity-50"
                onClick={() => {
                  !loadingAvatar &&
                    !isProfileMenuOpen &&
                    setIsProfileMenuOpen(true)
                }}
                aria-label="Account"
                aria-haspopup="true"
              >
                <Avatar
                  // @ts-ignore
                  disabled={loadingAvatar}
                  className="align-middle w-12 h-12 disabled:opacity-50"
                  style={{
                    width: "2.8rem",
                    height: "2.8rem",
                  }}
                  src={
                    loadingAvatar
                      ? "/gifs/loading.gif"
                      : (profile && profile.pic) || avatar || "/images/user.png"
                  }
                  alt=""
                  aria-hidden="true"
                />
              </button>
              <Dropdown
                align="right"
                isOpen={isProfileMenuOpen}
                onClose={() => {
                  setAreYouSure(false)
                  delay(100).then(() => setIsProfileMenuOpen(false))
                }}
              >
                <DropdownItem
                  className={areYouSure ? "bg-red-200 hover:bg-red-400" : ""}
                  onClick={(e) => {
                    if (areYouSure) {
                      notify.show("Cleaning the App...", "success")
                      localStorage.clear()
                      navigate("/", { replace: true })
                    } else {
                      setAreYouSure(true)
                    }
                    e.preventDefault()
                  }}
                >
                  {areYouSure ? (
                    <>
                      <InterrogationIcon
                        className="w-4 h-4 mr-3"
                        aria-hidden="true"
                      />
                      <span>Are you sure?</span>
                    </>
                  ) : (
                    <>
                      <TrashIcon className="w-4 h-4 mr-3" aria-hidden="true" />
                      <span>Clear App</span>
                    </>
                  )}
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    navigate("/elevenlabs/add")
                  }}
                >
                  <AudioIcon className="w-4 h-4 mr-3" aria-hidden="true" />
                  <span>Add EL Key</span>
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    notify.show("Loged out!", "success")
                    localStorage.setItem("token", "")
                    navigate("/", { replace: true })
                  }}
                >
                  <OutlineLogoutIcon
                    className="w-4 h-4 mr-3"
                    aria-hidden="true"
                  />
                  <span>Log out</span>
                </DropdownItem>
              </Dropdown>
            </li>
          </ul>
        </div>
      </header>
      <div className="w-full h-8" />
    </>
  )
}

export default Header
