import { useMutation } from "react-query"
import axios from "axios"
import { API } from "../contants"
import { notify } from "react-notify-toast"

// @app.route("/api/v1/elevenlabs/add", methods=["POST"])
// @cross_origin(supports_credentials=True)
// def add_elevenlabs_api_key():
//     details = request.get_json()  # store the json body request
//     email = details.get("email")
//     key = details.get("key")
//     characters = details.get("characters")
//     password = details.get("password")

async function addElevenlabsKey({
  email,
  password,
  key,
  characters,
  remainingDays,
}) {
  try {
    const options = {
      method: "POST",
      url: API + "/api/v1/elevenlabs/add",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      crossDomain: true,
      data: { email, password, key, characters, remainingDays },
    }

    const response = await axios.request(options)

    const message = response.data.msg
    const status = response.status

    if (status === 200) notify.show(message, "success")
    else notify.show(message, "error")

    return message
  } catch (error) {
    const error_msg = "Adding Elevenlabs Key failed"
    notify.show(error_msg, "error")
    throw new Error(error_msg)
  }
}

export function useAddElevenlabsKeysMutation() {
  return useMutation("elevenlabs", addElevenlabsKey)
}
