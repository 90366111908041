import { useEffect, useMemo, useState } from "react"

const defaultConfig = {
  optional: { check: false, min: -1, max: Number.POSITIVE_INFINITY },
  required: { check: true, min: 10, max: 800 },
  maxSentenceLen: 240,
}

export const useCheckSentences = (sentences = "", config = defaultConfig) => {
  const [optionalCounter, setOptionalCounter] = useState(0)
  const [requiredCounter, setRequiredCounter] = useState(0)
  const [bigSentenceFound, setBigSentenceFound] = useState(false)

  useEffect(() => {
    const counterMap = {
      optional: 0,
      required: 0,
      biggerSentence: 0,
    }
    sentences.split("\n").map((sentence) => {
      const cleanedSentence = sentence.replace(/ /g, "")
      if (cleanedSentence.length > counterMap.biggerSentence)
        counterMap.biggerSentence = cleanedSentence.length
      if (cleanedSentence.startsWith("*")) {
        counterMap.optional += cleanedSentence.replace(/\*/g, "").length
      } else {
        counterMap.required += cleanedSentence.length
      }
      setOptionalCounter(counterMap.optional)
      setRequiredCounter(counterMap.required)
    })
    setBigSentenceFound(counterMap.biggerSentence > config.maxSentenceLen)
  }, [config.maxSentenceLen, sentences])

  return useMemo(
    () => ({
      optional: {
        counter: optionalCounter,
        valid:
          optionalCounter > config.optional.min &&
          optionalCounter < config.optional.max,
      },
      required: {
        counter: requiredCounter,
        valid:
          requiredCounter > config.required.min &&
          requiredCounter < config.required.max,
      },
      config,
      bigSentenceFound,
    }),
    [bigSentenceFound, config, optionalCounter, requiredCounter]
  )
}
