import { hotjar } from 'react-hotjar'

export default function HotjarWrapper({ children }) {
  hotjar.initialize(4979989, 6)

  return (
    <>
      {children}
    </>
  )
}
