import React, { useState, useEffect, memo, useCallback } from "react"
import useStickyState from "../hooks/useStickyState"
import { FileUploader } from "react-drag-drop-files"
import { Label } from "@roketid/windmill-react-ui"
import VideoGrid from "../components/VideoGrid"
import useCutClip from "../mutations/useCutClip"
import { API } from "../contants"
import delay from "../functions/delay"
import useGetTotalTime from "../hooks/useGetTotalTime"
import getFileTag from "../functions/getFileTag"
import { notify } from "react-notify-toast"

export const SCENE_SEPARATOR = "___"

export const generateOnDelete = (setter, key, callback = (key) => { }) => {
  return () => {
    setter((prev) => {
      const prevClone = { ...prev }
      delete prevClone[key]
      callback && callback(key)
      return {
        ...prevClone,
      }
    })
  }
}

export const generateOnMerge = (setter, key) => {
  return () => {
    setter((prev) => {
      const prevClone = { ...prev }
      prevClone[key]
      if (prevClone[key]) {
        prevClone[key].merge_with_next = !prevClone[key].merge_with_next
      }
      return {
        ...prevClone,
      }
    })
  }
}

export const generateOnLoop = (setter, key) => {
  return (v) => {
    setter((prev) => {
      const prevClone = { ...prev }
      prevClone[key]
      if (prevClone[key]) {
        prevClone[key].loop = v && v > 0 ? v : 0
      }
      return {
        ...prevClone,
      }
    })
  }
}

const VideosRenderer = memo(
  ({
    setter,
    clips,
    isLoadingClips,
    disableLinking = false,
    disableLooping = false,
    disableLoopPlus = false,
    onDeleteClip = () => { },
  }) => {
    const [isDeleting, setIsDeleting] = useState(false)

    useEffect(() => {
      if (isDeleting) {
        delay(500).then(() => setIsDeleting(false))
      }
    }, [isDeleting])

    const clipsKeys = Object.keys(clips)

    return (
      <VideoGrid
        disableLinking={disableLinking}
        disableLooping={disableLooping}
        disableLoopPlus={disableLoopPlus}
        disabled={isDeleting || isLoadingClips}
        clips={clipsKeys.map((key, index) => {
          const { src, merge_with_next, loop } = clips[key]
          const hasNext = clipsKeys.length - 1 > index

          return {
            src: API + src,
            file_tag: key,
            index: key,
            merge_with_next,
            loop: loop || 0,
            onDelete: isLoadingClips
              ? () => { }
              : generateOnDelete(setter, key, onDeleteClip),
            onMerge:
              isLoadingClips || !hasNext
                ? () => { }
                : generateOnMerge(setter, key),
            onLoop: isLoadingClips ? () => { } : generateOnLoop(setter, key),
          }
        })}
      />
    )
  }
)

const fileTypes = ["MP4"]

export const AddFormVideoContainer = memo(({ isScenesValid, isLoading, minVideoTime, setClipsCb, setScenesCb }) => {
    const cutClip = useCutClip()

    const [uploading, setUploading] = useState(false)
    const [hasNewInput, setHasNewInput] = useState(false)
    const [clipsData, setClipsData] = useState({})

    const [clips, setClips] = useStickyState({}, `_clips`)
    const [scenes, setScenes] = useStickyState({}, `_scenes`)

    useEffect(() => {
      setClipsCb(clips)
      setScenesCb(scenes)
    }, [clips, scenes, setClipsCb, setScenesCb])

    const { totalTime } = useGetTotalTime(scenes)

    const hasMinimumVideoTime = !minVideoTime || totalTime >= minVideoTime

    useEffect(() => {
      if (uploading || isLoading || !hasNewInput) return

      setHasNewInput(false)
      setUploading(true)
      Promise.all(
        Object.keys(clipsData).map(async (fileTag) => {
          const file = clipsData[fileTag]

          if (!file) return

          const { scenes, reversedScenes, src } = await cutClip({
            file,
          })

          setClips((prev) => {
            const prevClone = { ...prev }
            const loadingClips = {}
            const loadingClipsKeys = Object.keys(clipsData).filter((key) => {
              if (!prevClone[key].src) {
                loadingClips[key] = prevClone[key]
                return true
              }
              return false
            })

            loadingClipsKeys.forEach((key) => {
              delete prevClone[key]
            })

            const prevResult = {
              ...prevClone,
              [fileTag]: {
                ...(loadingClips[fileTag] || {}),
                src,
                scenes,
                merge_with_next: false,
                loop: 0,
              },
            }

            delete loadingClips[fileTag]

            return {
              ...prevResult,
              ...loadingClips,
            }
          })

          if (scenes) {
            scenes.map(([start, end, sceneSrc], index) => {
              const sceneKey = `${fileTag}${SCENE_SEPARATOR}${index}`
              setScenes((prev) => ({
                ...prev,
                [sceneKey]: {
                  src: sceneSrc,
                  loopSrc: reversedScenes[index][2],
                  start,
                  end,
                  merge_with_next: false,
                  loop: 0,
                },
              }))
            })
          }
        })
      ).then(() => {
        setUploading(false)
        setClipsData({})
      })
    }, [
      clips,
      clipsData,
      cutClip,
      hasNewInput,
      isLoading,
      setClips,
      setScenes,
      uploading,
    ])

    useEffect(() => {
      const clipsKeys = Object.keys(clips)
      const clipsDataKeys = Object.keys(clipsData)

      clipsKeys.forEach((key) => {
        const { src } = clips[key]
        if (!src && !clipsDataKeys.includes(key)) {
          const deleteStillLoadingClip = generateOnDelete(
            setClips,
            key,
            (key) => {
              Object.keys(scenes).map((sceneKey) => {
                if (sceneKey.startsWith(key)) {
                  const deleteCurrentScene = generateOnDelete(
                    setScenes,
                    sceneKey
                  )
                  deleteCurrentScene()
                }
              })
            }
          )
          deleteStillLoadingClip()
        }
      })
    }, [clips, clipsData, scenes, setClips, setScenes])

    const handleClipsInput = useCallback(
      (files) => {
        Object.values(files).forEach((file) => {
          const fileTag = getFileTag(file)

          setHasNewInput(true)

          setClips((prev) => {
            if (prev[fileTag]) {
              notify.show(`File ${file.name} already used!`, "error")
              return prev
            }

            setClipsData((prevInput) => {
              return {
                ...prevInput,
                [fileTag]: file,
              }
            })

            return {
              ...prev,
              [fileTag]: {
                src: "",
                scenes: [],
                merge_with_next: false,
                loop: 0,
              },
            }
          })
        })
      },
      [setClips]
    )

    return (
      <div className="px-4 py-3 mb-8 bg-white rounded-lg shadow-md dark:bg-gray-800">
        <Label
          onClick={(e) => {
            e.preventDefault()
          }}
        >
          <span className={`${isScenesValid ? "" : "text-red-500"}`}>
            Product Videos
          </span>
          <div className="mt-2 mb-4 w-full flex items-center justify-center file-uploader-wrapper">
            <FileUploader
              disabled={uploading}
              className="disabled:opacity-30 file-uploader"
              multiple={true}
              handleChange={handleClipsInput}
              name="clips"
              types={fileTypes}
              required
            />
          </div>
          {!!Object.keys(clips).length && (
            <div
              className="
        mt-4
        gap-6
        mb-8
        items-center
        justify-between
        align-middle
        border-2
        border-dashed
        border-[#0658c2]
        border-opacity-30
        min-h-64
        p-4
        rounded-xl
        grid"
            >
              <VideosRenderer
                setter={setClips}
                isLoadingClips={uploading}
                clips={clips}
                disableLinking
                disableLooping
                onDeleteClip={(key) => {
                  Object.keys(scenes).map((sceneKey) => {
                    if (sceneKey.startsWith(key)) {
                      const deleteCurrentScene = generateOnDelete(
                        setScenes,
                        sceneKey
                      )
                      deleteCurrentScene()
                    }
                  })
                }}
              />
            </div>
          )}
        </Label>

        {!!Object.keys(scenes).length && (
          <Label className="mt-4">
            <div className="w-full flex flex-row justify-between">
              <span className={`${isScenesValid ? "" : "text-red-500"}`}>
                Clips
              </span>
            </div>
            <div
              className="
        mt-4
        gap-6
        mb-4
        items-center
        justify-between
        align-middle
        border-2
        border-dashed
      border-[#0658c2]
        border-opacity-30
        min-h-64
        p-4
        rounded-xl
        grid"
            >
              <VideosRenderer
                setter={setScenes}
                isLoadingClips={uploading}
                clips={scenes}
                disableLoopPlus={!!hasMinimumVideoTime}
              />
            </div>
            <div className="mb-4">
              <span
                className={`${hasMinimumVideoTime ? "text-green-600" : "text-red-500"
                  }`}
              >
                Total time: ~{Math.floor(totalTime)}s (Required: {minVideoTime}
                s)
              </span>
            </div>
          </Label>
        )}
      </div>
    )
  }
)
