import React from "react"

function ThemedSuspense() {
  return (
    <div className="absolute top-0 left-0 z-50 w-screen h-screen p-6 text-lg font-medium text-gray-600 dark:text-gray-400 bg-white dark:bg-gray-900 bg-opacity-30 backdrop-blur-md">
      Loading...
    </div>
  )
}

export default ThemedSuspense
