import { useCallback } from "react"
import { useQuery } from "react-query"
import axios from "axios"
import { useNavigate } from "react-router-dom"
import { API } from "../contants"
import { notify } from "react-notify-toast"

export default function useGetUser(refetchOnWindowFocus = true) {
  const navigate = useNavigate()
  // { isLoading, isError, data, error, refetch }
  const useGetUserCallback = useCallback(
    async (...data) => {
      const token = localStorage.getItem("token")

      if (!token) {
        navigate("/login", { replace: true })
      }

      try {
        const options = {
          method: "GET",
          url: API + "/api/v1/user",
          headers: {
            "Content-Type":
              "multipart/form-data; boundary=---011000010111000001101001",
            Authorization: `Bearer ${token}`,
          },
          crossDomain: true,
        }

        const response = await axios.request(options)
        const profile = response.data.profile

        if (!profile.email) {
          navigate("/login", { replace: true })
        }

        return profile
      } catch (error) {
        refetchOnWindowFocus && notify.show("Please login again.", "error")
        localStorage.setItem("token", "")
        navigate("/login", { replace: true })
        throw new Error("Invalid token. Please login again.")
      }
    },
    [navigate, refetchOnWindowFocus]
  )

  return useQuery(["get-user"], useGetUserCallback, {
    retry: 2,
    refetchOnWindowFocus,
  })
}
