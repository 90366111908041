import { useCallback } from "react"
import { useQuery } from "react-query"
import axios from "axios"
import { API } from "../contants"
import { notify } from "react-notify-toast"

export default function useGetAudio(
  _id,
  sentences,
  lang,
  voice,
  onSuccess,
  reset = false
) {
  const useGetAudioCallback = useCallback(async () => {
    if (!lang || !sentences || !voice) return
    try {
      const options = {
        method: "POST",
        url: API + (reset ? "/cdn/v1/audios/reset" : "/cdn/v1/audios/create"),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        crossDomain: true,
        data: {
          lang,
          sentences,
          voice,
        },
      }

      const response = await axios.request(options)

      onSuccess && onSuccess(response.data)

      return response.data
    } catch (error) {
      const error_msg = "Error while fetching audio"
      notify.show(error_msg, "error")
      throw new Error(error_msg)
    }
  }, [lang, onSuccess, reset, sentences, voice])

  return useQuery([`${_id}_get_audio`], useGetAudioCallback, {
    refetchOnWindowFocus: false,
    enabled: false,
  })
}
