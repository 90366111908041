import axios from "axios"
import { API } from "../contants"
import { notify } from "react-notify-toast"
import { useMemo } from "react"
import { generateFileHash } from "../hooks/useGenerateFileHash"

async function cut({ file }) {
  const hash = await generateFileHash(file)
  const form = new FormData()
  form.append("file", file)
  try {
    const options = {
      method: "POST",
      url: API + `/cdn/v2/detect/${hash}`,
      headers: {
        "Content-Type":
          "multipart/form-data; boundary=---011000010111000001101001",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      crossDomain: true,
      data: form,
    }

    const response = await axios.request(options)

    if (response.data.status === "processing") return cut({ file })

    const path = response.data.path
    const scenes = response.data.scenes
    const reversedScenes = response.data.reversed_scenes

    return {
      src: path,
      path,
      scenes,
      reversedScenes,
    }
  } catch (error) {
    const error_msg = "Error while cutting clip"
    notify.show(error_msg, "error")
    console.log(error)
    throw new Error(error_msg)
  }
}

export default function useCutClip() {
  return useMemo(() => cut, [])
}
