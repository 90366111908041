import { useCallback } from "react"
import { useQuery } from "react-query"
import axios from "axios"
import { API } from "../contants"
import { notify } from "react-notify-toast"

export default function useTranslate(_id, sentences, source, dest, onSuccess) {
  // { isLoading, isError, data, error, refetch }
  const useTranslateCallback = useCallback(async () => {
    try {
      const options = {
        method: "POST",
        url: API + "/api/v2/translate",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        data: {
          lang: source,
          sentences,
          languages: [dest],
        },
      }

      const response = await axios.request(options)
      const translated_sentences = response.data.translated_sentences[dest]

      onSuccess && onSuccess(translated_sentences)

      return translated_sentences
    } catch (error) {
      const error_msg = "Error while translating"
      notify.show(error_msg, "error")
      throw new Error(error_msg)
    }
  }, [source, sentences, dest, onSuccess])

  return useQuery([`${_id}_translate`], useTranslateCallback, {
    refetchOnWindowFocus: false,
    enabled: false,
  })
}
