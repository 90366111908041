import React from "react"
import VideoPlayer from "./VideoPlayer"

const VideoGrid = ({
  clips,
  className = "",
  disableLinking,
  disableLooping,
  disableLoopPlus,
  disabled,
}) => {
  return (
    <div className="grid overflow-x-scroll">
      <div
        className={`${className} ${
          disabled ? "pointer-events-none" : ""
        } select-none whitespace-nowrap inline-block`}
        onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()
        }}
      >
        {clips.map(
          (
            { src, rSrc, onDelete, onMerge, onLoop, loop, merge_with_next },
            index
          ) => (
            <VideoPlayer
              disableLinking={disableLinking}
              disableLooping={disableLooping}
              disableLoopPlus={disableLoopPlus}
              key={index}
              onDelete={onDelete}
              src={src}
              rSrc={rSrc}
              disabled={disabled}
              onMerge={onMerge}
              onLoop={onLoop}
              isLastItem={clips.length - 1 === index}
              isMerged={merge_with_next}
              loopCounter={loop}
            />
          )
        )}
      </div>
    </div>
  )
}

export default VideoGrid
