import { createContext, useContext, useState } from "react";

export const AudioContext = createContext(['', () => {}])

export const AudioProvider = ({children}) => {
  const [currentPlayingUrl, setCurrentPlayingUrl] = useState('');
  const [currentVolume, setCurrentVolume] = useState(1);

  return (
    <AudioContext.Provider value={[currentPlayingUrl, setCurrentPlayingUrl, currentVolume, setCurrentVolume]}>
      {children}
    </AudioContext.Provider>
  )
}

export const useCurrentAudio = () => {
  return useContext(AudioContext);
}
