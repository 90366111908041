import { useState } from "react"
import useGetAudio from "../queries/useGetAudio"

export const ResetAudioButton = ({
  _id,
  sentence,
  lang,
  voice,
  disabled,
  callback = () => {},
}) => {
  const [ask, setAsk] = useState(false)
  const { isFetching, refetch } = useGetAudio(
    _id,
    sentence,
    lang,
    voice,
    callback,
    true
  )

  return (
    <button
      disabled={isFetching || disabled}
      onClick={() => {
        if (disabled) return
        if (!ask) setAsk(true)
        else {
          refetch()
          setAsk(false)
        }
      }}
      onBlur={() => setAsk(false)}
      className={`
      text-xs disabled:opacity-40 disabled:cursor-default
      ${
        ask
          ? "text-red-700 dark:text-red-400 px-2"
          : "text-white dark:text-gray-50 px-2"
      } 
      bg-indigo-500 hover:bg-indigo-700 rounded-full`}
    >
      {ask ? "Are you sure?" : "Reset Audio"}
    </button>
  )
}
