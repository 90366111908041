import React, { useState } from "react"
import { Label, Input } from "@roketid/windmill-react-ui"
import { AudioPlayer } from "./AudioPlayer"

export const SoundsList = ({
  title,
  selected,
  list,
  setSelected,
  isLoading,
}) => {
  return (
    <>
      <Label radio className="">
        <span className={`text-sm ${selected ? "" : "text-red-500"}`}>
          {title}
        </span>
      </Label>
      <div className="relative flex flex-col gap-x-6 gap-y-2 mb-4 mt-2 max-h-48 overflow-y-scroll border border-gray-100 py-6 px-6 rounded-lg">
        {isLoading && (
          <div className="absolute w-full h-full left-0 top-0 cursor-not-allowed bg-gray-100 opacity-60"></div>
        )}
        {list.map(({ name, url }) => {
          return (
            <Label radio className="flex flex-row gap-x-6 ">
              <Input
                crossOrigin="use-credentials"
                onPointerLeaveCapture={undefined}
                type="radio"
                value={name}
                name={name}
                checked={selected === name}
                onChange={(e) => {
                  setSelected(name)
                }}
              />
              <AudioPlayer 
                url={url}
              />
              <span className="ml-2 whitespace-nowrap">{name}</span>
            </Label>
          )
        })}
      </div>
    </>
  )
}
