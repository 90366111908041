import sha256 from "crypto-js/sha256"
import slugify from "@sindresorhus/slugify"

function encodeString(input) {
  return sha256(input).toString()
}

export default function getFileTag(file) {
  const encripted_slug = encodeString(
    slugify(`${file.name}_${file.size}_${file.type}`)
  )

  return encripted_slug
}
