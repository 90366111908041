import React, { useCallback, useState, useEffect, useMemo } from "react"
import useStickyState from "../hooks/useStickyState"
import { FileUploader } from "react-drag-drop-files"
import { Input, Label, Textarea } from "@roketid/windmill-react-ui"
// import isFileInList from "../functions/isFileInList"
import useTranslate from "../queries/useTranslate"
import useGetUser from "../queries/useGetUser"
import SectionTitle from "../components/Typography/SectionTitle"
// import useGetClip from "../mutations/useGetClip"
// import useDeleteClip from "../mutations/useDeleteClip"
import useGetCode from "../queries/useGetCode"
import { API } from "../contants"
import { notify } from "react-notify-toast"
import { useCheckSentences } from "../hooks/useCheckSentences"
import { useValidateData } from "../hooks/useValidateData"
import useCreateProduct from "../mutations/useCreateProduct"
import useGetTotalTime from "../hooks/useGetTotalTime"
import { AudioPlayer } from "../components/AudioPlayer"
import "react-h5-audio-player/lib/styles.css"
// import { AudioIcon } from "../icons"
import useGetAudio from "../queries/useGetAudio"
import useGetSounds from "../queries/useGetSounds"
import { useDebouncedCallback } from "../hooks/useDebouncedCallback"
import { SoundsList } from "../components/SoundList"
import {
  AddFormVideoContainer,
} from "./AddFormVideoContainer"
// import TinyAudioPlayer from "react-tiny-audio-player"
import clearLocalStorageExceptOneItem from "../functions/clearLocalStorageExceptOneItem"

const LangInput = ({
  isFetching = false,
  placeholder = "",
  onChange = (e) => { },
  onTranslate,
  isGetAudioLoading = false,
  showGetAudio = false,
  onGetAudio = (e) => { },
  editable = false,
  value = "",
  disabled = false,
  disabledButton = false,
  disabledAudioButton = true,
  buttonTitle = "",
  textArea = false,
  hasAudio = false,
  audioTime = 0,
  audiosUrls = [],
}) => {
  const [localValue, setLocalValue] = useState(value)
  const setOnChangeDebounced = useDebouncedCallback(onChange, 100)
  const localOnChange = (v) => {
    setLocalValue(v)
    setOnChangeDebounced(v)
  }

  useEffect(() => {
    !editable && setLocalValue(value)
  }, [editable, value])

  return (
    <>
      <div className="inline-block w-full text-gray-500 focus-within:text-purple-600 overflow-scroll">
        <div className={`relative ${showGetAudio ? "min-w-[500px]" : ""}`}>
          {textArea ? (
            <Textarea
              className={`mt-1 ${showGetAudio ? "pr-[280px]" : "pr-[100px]"}`}
              placeholder={isFetching ? "..." : placeholder}
              onChange={(e) => localOnChange(e.target.value)}
              value={localValue}
              disabled={disabled}
              rows={6}
              onPointerLeaveCapture={undefined}
            />
          ) : (
            <Input
              crossOrigin="use-credentials"
              onPointerLeaveCapture={undefined}
              className={`mt-1 ${showGetAudio ? "pr-[280px]" : "pr-[100px]"}`}
              placeholder={isFetching ? "..." : placeholder}
              onChange={(e) => localOnChange(e.target.value)}
              value={localValue}
              disabled={disabled}
            />
          )}
          <div
            className={`flex justify-center gap-2 absolute inset-y-0 right-0 h-full`}
          >
            {!!(showGetAudio || isGetAudioLoading) && (
              // <div
              //   className={`absolute w-5 h-5 top-1/2 inset-y-0 -left-12 p-0 m-0 -translate-y-1/2 px-4 text-sm font-medium leading-5 text-white ${
              //     disabledAudioButton ? "text-[#707275]" : "cursor-pointer"
              //   } dark:text-white transition-colors duration-150 bg-gray-100 hover:bg-gray-200 border disabled:text-opacity-20 rounded-l-md`}
              //   style={{
              //     padding: 0,
              //   }}
              // >
              //   <div className="flex items-center align-middle justify-end h-6">
              //     <AudioIcon
              //       onClick={disabledAudioButton ? () => {} : onGetAudio}
              //       className={`${
              //         disabledAudioButton ? "opacity-20" : "cursor-pointer"
              //       } w-5 h-5 text-green-700 hover:text-green-900`}
              //     />
              //   </div>
              // </div>
              <button
                disabled={disabledAudioButton || isGetAudioLoading}
                onClick={onGetAudio}
                className={`${isGetAudioLoading ? 'opacity-20' : ' '} min-w-[88px] text-sm h-full font-medium px-4 leading-5 text-white disabled:text-[#707275] disabled:dark:text-white dark:text-white transition-colors duration-150 bg-purple-600 border border-transparent ${disabled
                  ? "disabled:bg-transparent"
                  : "disabled:bg-opacity-20 disabled:bg-gray-300 disabled:text-opacity-75"
                  } rounded-l-md active:bg-purple-600 hover:bg-purple-700 focus:outline-none focus:shadow-outline-purple flex items-center align-middle justify-end h-6`}
              >
                {isGetAudioLoading && (
                  <div className="lds-facebook absolute -left-2">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                )}
                {showGetAudio &&
                  (audiosUrls.length
                    ? `Recreate Audio`
                    : "Create Audio")}
              </button>
            )}
           {
            onTranslate && <button
              disabled={disabled || disabledButton}
              onClick={onTranslate}
              className={`min-w-[88px] text-sm h-full font-medium px-4 leading-5 text-white disabled:text-[#707275] disabled:dark:text-white dark:text-white transition-colors duration-150 bg-purple-600 border border-transparent ${disabled
                ? "disabled:bg-[#b9b9b9]"
                : "disabled:bg-opacity-20 disabled:bg-gray-300 disabled:text-opacity-75"
                } rounded-r-md active:bg-purple-600 hover:bg-purple-700 focus:outline-none focus:shadow-outline-purple flex items-center align-middle justify-end h-6`}
            >
              {!!isFetching && (
                <div className="lds-facebook absolute -left-2">
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              )}
              {buttonTitle}
            </button>
            }
          </div>
        </div>
      </div>
      <div className="w=full mb-4">
        {hasAudio &&
          audiosUrls.map((currentUrl, index) => (
            <AudioPlayer
              key={index}
              url={currentUrl}
            />
          ))}
      </div>
    </>
  )
}

const statusMap = {
  pt: "Portuguese",
  en: "English",
  es: "Spanish",
  fr: "French",
  it: "Italian"
}


function AddForm() {
  const {
    data: code,
    isLoading: isGetCodeLoading,
    refetch: fetchCode,
  } = useGetCode()

  const { data } = useGetUser()
  const email = data && data.email

  const createProduct = useCreateProduct()

  const [submitting, setSubmitting] = useState(false)

  const [pt, setPt] = useStickyState("", `_pt`)
  const [en, setEn] = useStickyState("", `_en`)
  const [es, setEs] = useStickyState("", `_es`)
  const [fr, setFr] = useStickyState("", `_fr`)
  const [it, setIt] = useStickyState("", `_de`)

  // const [ptAudio, setPtAudio] = useStickyState([], `_pt_audio`)
  // const [enAudio, setEnAudio] = useStickyState([], `_en_audio`)
  // const [esAudio, setEsAudio] = useStickyState([], `_es_audio`)
  // const [frAudio, setFrAudio] = useStickyState([], `_fr_audio`)
  // const [deAudio, setItAudio] = useStickyState([], `_de_audio`)

  const { isFetching: isFetchingEn, refetch: refetchEn } = useTranslate(
    "EN",
    pt,
    "pt",
    "en",
    setEn
  )
  const { isFetching: isFetchingEs, refetch: refetchEs } = useTranslate(
    "ES",
    pt,
    "pt",
    "es",
    setEs
  )
  const { isFetching: isFetchingFr, refetch: refetchFr } = useTranslate(
    "FR",
    pt,
    "pt",
    "fr",
    setFr
  )
  const { isFetching: isFetchingIt, refetch: refetchIt } = useTranslate(
    "DE",
    pt,
    "pt",
    "it",
    setIt
  )

  const isTitleTranslationLoading =
    isFetchingEn || isFetchingFr || isFetchingEs || isFetchingIt

  const [selectedMusic, setSelectedMusic] = useStickyState("", `_videoMusic`)
  const [selectedVoice, setSelectedVoice] = useStickyState("", `_videoVoice`)

  const [ptSent, setPtSent] = useStickyState("", `_ptSent`)
  const [enSent, setEnSent] = useStickyState("", `_enSent`)
  const [esSent, setEsSent] = useStickyState("", `_esSent`)
  const [frSent, setFrSent] = useStickyState("", `_frSent`)
  const [itSent, setItSent] = useStickyState("", `_deSent`)

  const getAudioLen = useCallback(
    (audio) =>
      (audio &&
        audio.audio &&
        audio.audio.audio_info &&
        audio.audio.audio_info.length) ||
      0,
    []
  )

  const getAudiosUrls = useCallback((audios = []) => {
    if (!audios || audios.some((audio) => {
      return !audio || audio.status === "processing" || audio.status === "new"
    })) return []

    return audios.map(({ audio: { hash, lang, audio_file_name } }) => {
      return API + `/cdn/v1/audio/${hash}/${lang}/${audio_file_name}`
    })
  }, [])

  const [ptSentAudio, setPtSentAudio] = useStickyState([], `_pt_sent_audio`)
  const ptSentAudioTotalTime = ptSentAudio.length
    ? ptSentAudio.reduce((prev, curr) => prev + getAudioLen(curr), 0)
    : 0
  const ptSentAudioUrls = getAudiosUrls(ptSentAudio)
  const [enSentAudio, setEnSentAudio] = useStickyState([], `_en_sent_audio`)
  const enSentAudioTotalTime = enSentAudio.length
    ? enSentAudio.reduce((prev, curr) => prev + getAudioLen(curr), 0)
    : 0
  const enSentAudioUrls = getAudiosUrls(enSentAudio)
  const [esSentAudio, setEsSentAudio] = useStickyState([], `_es_sent_audio`)
  const esSentAudioTotalTime = esSentAudio.length
    ? esSentAudio.reduce((prev, curr) => prev + getAudioLen(curr), 0)
    : 0
  const esSentAudioUrls = getAudiosUrls(esSentAudio)
  const [frSentAudio, setFrSentAudio] = useStickyState([], `_fr_sent_audio`)
  const frSentAudioTotalTime = frSentAudio.length
    ? frSentAudio.reduce((prev, curr) => prev + getAudioLen(curr), 0)
    : 0
  const frSentAudioUrls = getAudiosUrls(frSentAudio)
  const [itSentAudio, setItSentAudio] = useStickyState([], `_de_sent_audio`)
  const itSentAudioTotalTime = itSentAudio.length
    ? itSentAudio.reduce((prev, curr) => prev + getAudioLen(curr), 0)
    : 0
  const itSentAudioUrls = getAudiosUrls(itSentAudio)

  const minVideoTime =
    Math.ceil(
      Math.max(
        ptSentAudioTotalTime,
        enSentAudioTotalTime,
        esSentAudioTotalTime,
        frSentAudioTotalTime,
        itSentAudioTotalTime,
        0
      )
    ) || 0

  const { isFetching: isFetchingPtSentAudio, refetch: refetchPtSentAudio } =
    useGetAudio("PT_AUDIO_SENT", ptSent, "pt", selectedVoice, (v) => {
      setPtSentAudio(v)
      // refetchEnSentAudio()
    })
  const { isFetching: isFetchingEnSentAudio, refetch: refetchEnSentAudio } =
    useGetAudio("EN_AUDIO_SENT", enSent, "en", selectedVoice, (v) => {
      setEnSentAudio(v)
      // refetchEsSentAudio()
    })
  const { isFetching: isFetchingEsSentAudio, refetch: refetchEsSentAudio } =
    useGetAudio("ES_AUDIO_SENT", esSent, "es", selectedVoice, (v) => {
      setEsSentAudio(v)
      // refetchFrSentAudio()
    })
  const { isFetching: isFetchingFrSentAudio, refetch: refetchFrSentAudio } =
    useGetAudio("FR_AUDIO_SENT", frSent, "fr", selectedVoice, (v) => {
      setFrSentAudio(v)
      // refetchItSentAudio()
    })
  const { isFetching: isFetchingItSentAudio, refetch: refetchItSentAudio } =
    useGetAudio("DE_AUDIO_SENT", itSent, "it", selectedVoice, (v) => {
      setItSentAudio(v)
    })

  // const isFetchingPtSentAudio =
  //   isFetchingPtSentAudio ||
  //   isFetchingEnSentAudio ||
  //   isFetchingEsSentAudio ||
  //   isFetchingFrSentAudio ||
  //   isFetchingItSentAudio

  const [fetchSentPtAudioEnabled, setFetchSentPtAudioEnabled] = useState(false)
  const [fetchSentEnAudioEnabled, setFetchSentEnAudioEnabled] = useState(false)
  const [fetchSentEsAudioEnabled, setFetchSentEsAudioEnabled] = useState(false)
  const [fetchSentFrAudioEnabled, setFetchSentFrAudioEnabled] = useState(false)
  const [fetchSentItAudioEnabled, setFetchSentItAudioEnabled] = useState(false)

  const isFullCompletedPt = ptSentAudio &&
    ptSentAudio.length &&
    !ptSentAudio.some(({ status }) => !status || status !== "completed")
  const isFullCompletedEn = enSentAudio &&
    enSentAudio.length &&
    !enSentAudio.some(({ status }) => !status || status !== "completed")
  const isFullCompletedEs = esSentAudio &&
    esSentAudio.length &&
    !esSentAudio.some(({ status }) => !status || status !== "completed")
  const isFullCompletedFr = frSentAudio &&
    frSentAudio.length &&
    !frSentAudio.some(({ status }) => !status || status !== "completed")
  const isFullCompletedIt = itSentAudio &&
    itSentAudio.length &&
    !itSentAudio.some(({ status }) => !status || status !== "completed")

  // PT
  useEffect(() => {
    if (fetchSentPtAudioEnabled && !isFetchingPtSentAudio && !isFullCompletedPt) {
      refetchPtSentAudio()
    } else if (
      isFullCompletedPt &&
      !isFetchingPtSentAudio &&
      fetchSentPtAudioEnabled
    ) {
      setFetchSentPtAudioEnabled(false)
    }
  }, [
    fetchSentPtAudioEnabled,
    isFullCompletedPt,
    isFetchingPtSentAudio,
    ptSentAudio,
    refetchPtSentAudio,
  ])

  // EN
  useEffect(() => {
    if (fetchSentEnAudioEnabled && !isFetchingEnSentAudio && !isFullCompletedEn) {
      refetchEnSentAudio()
    } else if (
      isFullCompletedEn &&
      !isFetchingEnSentAudio &&
      fetchSentEnAudioEnabled
    ) {
      setFetchSentEnAudioEnabled(false)
    }
  }, [
    fetchSentEnAudioEnabled,
    isFullCompletedEn,
    isFetchingEnSentAudio,
    ptSentAudio,
    refetchEnSentAudio,
  ])

  // ES
  useEffect(() => {
    if (fetchSentEsAudioEnabled && !isFetchingEsSentAudio && !isFullCompletedEs) {
      refetchEsSentAudio()
    } else if (
      isFullCompletedEs &&
      !isFetchingEsSentAudio &&
      fetchSentEsAudioEnabled
    ) {
      setFetchSentEsAudioEnabled(false)
    }
  }, [
    fetchSentEsAudioEnabled,
    isFullCompletedEs,
    isFetchingEsSentAudio,
    ptSentAudio,
    refetchEsSentAudio,
  ])

  // FR
  useEffect(() => {
    if (fetchSentFrAudioEnabled && !isFetchingFrSentAudio && !isFullCompletedFr) {
      refetchFrSentAudio()
    } else if (
      isFullCompletedFr &&
      !isFetchingFrSentAudio &&
      fetchSentFrAudioEnabled
    ) {
      setFetchSentFrAudioEnabled(false)
    }
  }, [
    fetchSentFrAudioEnabled,
    isFullCompletedFr,
    isFetchingFrSentAudio,
    ptSentAudio,
    refetchFrSentAudio,
  ])

  // IT
  useEffect(() => {
    if (fetchSentItAudioEnabled && !isFetchingItSentAudio && !isFullCompletedIt) {
      refetchItSentAudio()
    } else if (
      isFullCompletedIt &&
      !isFetchingItSentAudio &&
      fetchSentItAudioEnabled
    ) {
      setFetchSentItAudioEnabled(false)
    }
  }, [
    fetchSentItAudioEnabled,
    isFullCompletedIt,
    isFetchingItSentAudio,
    ptSentAudio,
    refetchItSentAudio,
  ])

  const { optional, required, config, bigSentenceFound } =
    useCheckSentences(ptSent)

  const { isFetching: isFetchingEnSent, refetch: refetchEnSent } = useTranslate(
    "ENSENT",
    ptSent,
    "pt",
    "en",
    setEnSent
  )
  const { isFetching: isFetchingEsSent, refetch: refetchEsSent } = useTranslate(
    "ESSENT",
    ptSent,
    "pt",
    "es",
    setEsSent
  )
  const { isFetching: isFetchingFrSent, refetch: refetchFrSent } = useTranslate(
    "FRSENT",
    ptSent,
    "pt",
    "fr",
    setFrSent
  )
  const { isFetching: isFetchingItSent, refetch: refetchItSent } = useTranslate(
    "DESENT",
    ptSent,
    "pt",
    "it",
    setItSent
  )

  const isSentTranslationLoading =
    isFetchingEnSent || isFetchingFrSent || isFetchingEsSent || isFetchingItSent

  const [aliLink, setAliLink] = useStickyState("", `_aliLink`)

  const [musicsList, setMusicsList] = useState([])
  const [voicesList, setVoicesList] = useState([])

  const { refetch: refetchMusic, isFetching: isFetchingMusic } = useGetSounds(
    "musics",
    setMusicsList
  )
  const { refetch: refetchVoices, isFetching: isFetchingVoices } = useGetSounds(
    "voices",
    setVoicesList
  )

  useEffect(() => {
    refetchMusic()
    refetchVoices()
  }, [])

  // const [tags, setTags] = useStickyState([], `_tags`)

  const [clips, setClips] = useStickyState({}, `_clips`)
  const [scenes, setScenes] = useStickyState({}, `_scenes`)

  const {
    isScenesValid,
    isAllTitlesValid,
    isPtSentValid,
    isEnSentValid,
    isEsSentValid,
    isFrSentValid,
    isItSentValid,
    isAllSentValid,
    isAliLinkValid,
    isAllValid
  } = useValidateData(
    code,
    clips,
    scenes,
    pt,
    ptSent,
    en,
    enSent,
    es,
    esSent,
    fr,
    frSent,
    it,
    itSent,
    aliLink,
    minVideoTime,
    selectedMusic,
    selectedVoice
    // tags
  )

  const handleSubmitData = useCallback(() => {
    const data = {
      code,
      clips,
      scenes,
      pt,
      ptSent,
      ptSentAudio,
      en,
      enSent,
      enSentAudio,
      es,
      esSent,
      esSentAudio,
      fr,
      frSent,
      frSentAudio,
      it,
      itSent,
      itSentAudio,
      aliLink,
      music: `${selectedMusic}.mp3`,
      voice: selectedVoice,
      email
      // tags,
    }

    setSubmitting(true)
    createProduct({ data })
      .then(() => {
        notify.show(`Success creating product for all languages`, "success")
        setSubmitting(false)
      })
      .catch((e) => {
        console.log(e)
        notify.show(
          `Error creating product, check console (f12) and send the Error to Will`,
          "error"
        )
        setSubmitting(false)
      })
  }, [
    aliLink,
    code,
    createProduct,
    it,
    itSent,
    itSentAudio,
    en,
    enSent,
    enSentAudio,
    es,
    clips,
    scenes,
    esSent,
    esSentAudio,
    fr,
    frSent,
    frSentAudio,
    pt,
    ptSent,
    ptSentAudio,
    selectedMusic,
    selectedVoice,
    email
  ])

  const onSelectedVoiceChange = useCallback(
    (v) => {
      setPtSentAudio([])
      setEnSentAudio([])
      setEsSentAudio([])
      setFrSentAudio([])
      setItSentAudio([])
      setEnSent("")
      setEsSent("")
      setFrSent("")
      setItSent("")
      setSelectedVoice(v)
    },
    [
      setItSent,
      setItSentAudio,
      setEnSent,
      setEnSentAudio,
      setEsSent,
      setEsSentAudio,
      setFrSent,
      setFrSentAudio,
      setPtSentAudio,
      setSelectedVoice,
    ]
  )

  const onChangePtSent = useCallback(
    (v) => {
      setPtSentAudio([])
      setPtSent(v)
    },
    [
      setPtSentAudio,
      setPtSent
    ]
  )

  const onChangeEnSent = useCallback(
    (v) => {
      setEnSentAudio([])
      setEnSent(v)
    },
    [
      setPtSentAudio,
      setPtSent
    ]
  )

  const onChangeEsSent = useCallback(
    (v) => {
      setEsSentAudio([])
      setEsSent(v)
    },
    [
      setPtSentAudio,
      setPtSent
    ]
  )

  const onChangeFrSent = useCallback(
    (v) => {
      setFrSentAudio([])
      setFrSent(v)
    },
    [
      setPtSentAudio,
      setPtSent
    ]
  )

  const onChangeItSent = useCallback(
    (v) => {
      setItSentAudio([])
      setItSent(v)
    },
    [
      setPtSentAudio,
      setPtSent
    ]
  )

  const resetAll = () => {
    setPtSentAudio([])
    setEnSentAudio([])
    setEsSentAudio([])
    setFrSentAudio([])
    setItSentAudio([])
    setEnSent("")
    setEsSent("")
    setFrSent("")
    setItSent("")
    setPtSent("")
    setSubmitting
    setPt("")
    setEn("")
    setEs("")
    setFr("")
    setIt("")
    setSelectedMusic("")
    setSelectedVoice("")
    setPtSent("")
    setEnSent("")
    setEsSent("")
    setFrSent("")
    setItSent("")
    setFetchSentPtAudioEnabled(false)
    setAliLink("")
    setClips({})
    setScenes({})
    setSubmitting(false)
  }

  const [selectedSentLangView, setSelectedSentLangView] = useState('pt')

  return (
    <>
      <div className="w-full flex justify-between">
        <SectionTitle>Add new product: {code}</SectionTitle>
        <button
          className="italic cursor-pointer text-red-600"
          onClick={() => {
            resetAll()
            clearLocalStorageExceptOneItem("token")
            window.location.reload()
          }}
        >
          <Label className="cursor-pointer text-red-600">Reset</Label>
        </button>
      </div>

      <div className="px-4 py-3 mb-8 bg-white rounded-lg shadow-md dark:bg-gray-800">
        <SoundsList
          list={musicsList}
          title="Video Music"
          selected={selectedMusic}
          setSelected={setSelectedMusic}
          isLoading={isFetchingMusic}
        />
        <SoundsList
          list={voicesList}
          title="Video Voice"
          selected={selectedVoice}
          setSelected={onSelectedVoiceChange}
          isLoading={isFetchingVoices}
        />
        <Label>
          <span className={`${isAllTitlesValid ? "" : "text-red-500"}`}>
            Title
          </span>
          <LangInput
            editable
            placeholder="Removedor de pelos de roupas!"
            buttonTitle={
              isTitleTranslationLoading ? "Translating..." : "Translate"
            }
            onChange={useCallback(
              (v) => {
                setEn("")
                setEs("")
                setFr("")
                setIt("")
                setPt(v)
              },
              [setIt, setEn, setEs, setFr, setPt]
            )}
            // disabledAudioButton={
            //   fetchSentPtAudioEnabled ||
            //   isFetchingPtSentAudio ||
            //   isTitleTranslationLoading ||
            //   !isAllTitlesValid
            // }
            disabledButton={!pt || isTitleTranslationLoading}
            value={pt}
            onTranslate={useCallback(async () => {
              if (pt) {
                await refetchEn()
                await refetchEs()
                await refetchFr()
                await refetchIt()
              }
            }, [pt, refetchIt, refetchEn, refetchEs, refetchFr])}
          />
          <LangInput
            isFetching={isFetchingEn}
            placeholder={pt ? "..." : "Clothes hair remover!"}
            buttonTitle="English"
            value={en}
            disabled
          />
          <LangInput
            isFetching={isFetchingEs}
            placeholder={pt ? "..." : "Removedor de pelo de ropa!"}
            buttonTitle="Spanish"
            value={es}
            disabled
          />
          <LangInput
            isFetching={isFetchingFr}
            placeholder={pt ? "..." : "Épilateur pour vêtements!"}
            buttonTitle="Franch"
            value={fr}
            disabled
          />
          <LangInput
            isFetching={isFetchingIt}
            placeholder={pt ? "..." : "Haarentferner für Kleidung!"}
            buttonTitle="Italian"
            value={it}
            disabled
          />
        </Label>

        <Label className="mt-4">
          <span className={`${isAliLinkValid ? "" : "text-red-500"}`}>
            Link
          </span>
          <Input
            crossOrigin="use-credentials"
            onPointerLeaveCapture={undefined}
            className="mt-1"
            placeholder="https://s.click.aliexpress.com/e/..."
            value={aliLink}
            onChange={(e) => setAliLink(e.target.value)}
          />
        </Label>

        <div className="flex flex-row gap-x-2 gap-y-2 max-w-[88vw] overflow-auto py-3 mt-4 p-2">
          <Label radio>
            <Input
              crossOrigin="use-credentials"
              onPointerLeaveCapture={undefined}
              type="radio"
              value="pt"
              name="selectedLang"
              checked={selectedSentLangView === "pt"}
              onChange={(e) => {
                setSelectedSentLangView(e.target.value)
              }}
            />
            <span className="ml-2">{statusMap.pt}</span>
          </Label>
          <Label radio>
            <Input
              crossOrigin="use-credentials"
              onPointerLeaveCapture={undefined}
              type="radio"
              disabled={!required.valid || !enSent}
              value="en"
              name="selectedLang"
              checked={selectedSentLangView === "en"}
              onChange={(e) => {
                setSelectedSentLangView(e.target.value)
              }}
            />
            <span className="ml-2">{statusMap.en}</span>
          </Label>
          <Label radio>
            <Input
              crossOrigin="use-credentials"
              onPointerLeaveCapture={undefined}
              type="radio"
              disabled={!required.valid || !esSent}
              value="es"
              name="selectedLang"
              checked={selectedSentLangView === "es"}
              onChange={(e) => {
                setSelectedSentLangView(e.target.value)
              }}
            />
            <span className="ml-2">{statusMap.es}</span>
          </Label>
          <Label radio>
            <Input
              crossOrigin="use-credentials"
              onPointerLeaveCapture={undefined}
              type="radio"
              disabled={!required.valid || !frSent}
              value="fr"
              name="selectedLang"
              checked={selectedSentLangView === "fr"}
              onChange={(e) => {
                setSelectedSentLangView(e.target.value)
              }}
            />
            <span className="ml-2">{statusMap.fr}</span>
          </Label>
          <Label radio>
            <Input
              crossOrigin="use-credentials"
              onPointerLeaveCapture={undefined}
              type="radio"
              disabled={!required.valid || !itSent}
              value="it"
              name="selectedLang"
              checked={selectedSentLangView === "it"}
              onChange={(e) => {
                setSelectedSentLangView(e.target.value)
              }}
            />
            <span className="ml-2">{statusMap.it}</span>
          </Label>
        </div>

        <Label className="mt-2">
          <div className="w-full flex flex-row justify-between">
            <span className={`${isAllSentValid ? "" : "text-red-500"}`}>
              Script
            </span>
          </div>
          {
            selectedSentLangView === 'pt' && <>
              <LangInput
                editable
                textArea
                placeholder="Um produto incrível..."
                buttonTitle={
                  isSentTranslationLoading ? "Translating..." : "Translate"
                }
                onChange={onChangePtSent}
                showGetAudio
                isGetAudioLoading={isFetchingPtSentAudio}
                onGetAudio={() => {
                  notify.show(`This may take a while, please wait...`, "warning")
                  setFetchSentPtAudioEnabled(true)
                }}
                disabledAudioButton={
                  !selectedVoice ||
                  !ptSent ||
                  !isPtSentValid ||
                  fetchSentPtAudioEnabled ||
                  isFetchingPtSentAudio ||
                  isSentTranslationLoading
                }
                
                disabledButton={
                  !ptSent ||
                  !isPtSentValid ||
                  fetchSentPtAudioEnabled ||
                  isFetchingPtSentAudio ||
                  isSentTranslationLoading
                }
                value={ptSent}
                onTranslate={required.valid ? async () => {
                  if (ptSent) {
                    await refetchEnSent()
                    await refetchEsSent()
                    await refetchFrSent()
                    await refetchItSent()
                  }
                } : undefined}
                hasAudio
                audioTime={ptSentAudioTotalTime}
                audiosUrls={ptSentAudioUrls}
              />
              <div className="flex gap-6 mt-2 mb-4 justify-between">
                {config.required.check && (
                  <span
                    className={`${required.valid ? "text-green-600" : "text-red-500"
                      }`}
                  >
                    Text Length: ({config.required.min} {"<"}{" "}
                    <strong>{required.counter}</strong> {"<"} {config.required.max})
                  </span>
                )}
                {config.optional.check && (
                  <span
                    className={`${optional.valid ? "text-green-600" : "text-red-500"
                      }`}
                  >
                    Optional Text: ({config.optional.min} {"<"}{" "}
                    <strong>{optional.counter}</strong> {"<"} {config.optional.max})
                  </span>
                )}
                {bigSentenceFound && (
                  <span className="text-red-500">
                    WARNING! Sentences must have {config.maxSentenceLen} characters
                    or less
                  </span>
                )}
              </div>
            </>
          }
          {
            selectedSentLangView === 'en' && <LangInput
              isFetching={isFetchingEnSent}
              textArea
              placeholder={ptSent ? "..." : "An amazing product..."}
              buttonTitle="English"
              value={enSent}
              // disabled
              disabledButton
              disabledAudioButton={
                !selectedVoice ||
                !enSent ||
                !isEnSentValid ||
                fetchSentEnAudioEnabled ||
                isFetchingEnSentAudio ||
                isSentTranslationLoading
              }
              hasAudio
              onChange={onChangeEnSent}
              showGetAudio
              isGetAudioLoading={isFetchingEnSentAudio}
              onGetAudio={() => {
                notify.show(`This may take a while, please wait...`, "warning")
                setFetchSentEnAudioEnabled(true)
              }}
              audioTime={enSentAudioTotalTime}
              audiosUrls={enSentAudioUrls}
            />
          }
          {
            selectedSentLangView === 'es' && <LangInput
              isFetching={isFetchingEsSent}
              textArea
              placeholder={ptSent ? "..." : "Un producto increíble..."}
              buttonTitle="Spanish"
              value={esSent}
              // disabled
              disabledButton
              disabledAudioButton={
                !selectedVoice ||
                !ptSent ||
                !isEsSentValid ||
                fetchSentEsAudioEnabled ||
                isFetchingEsSentAudio ||
                isSentTranslationLoading
              }
              hasAudio
              onChange={onChangeEsSent}
              showGetAudio
              isGetAudioLoading={isFetchingEsSentAudio}
              onGetAudio={() => {
                notify.show(`This may take a while, please wait...`, "warning")
                setFetchSentEsAudioEnabled(true)
              }}
              audioTime={esSentAudioTotalTime}
              audiosUrls={esSentAudioUrls}
            />
          }
          {/* <div className="flex gap-6 mt-2 mb-4 float-right">
            <span className="text-green-600 float-right">
              {Math.floor(esSentAudioTotalTime)}s
            </span>
          </div> */}
          {
            selectedSentLangView === 'fr' && <LangInput
              isFetching={isFetchingFrSent}
              textArea
              placeholder={ptSent ? "..." : "Un produit étonnant..."}
              buttonTitle="Franch"
              value={frSent}
              // disabled
              disabledButton
              disabledAudioButton={
                !selectedVoice ||
                !ptSent ||
                !isFrSentValid ||
                fetchSentFrAudioEnabled ||
                isFetchingFrSentAudio ||
                isSentTranslationLoading
              }
              hasAudio
              onChange={onChangeFrSent}
              showGetAudio
              isGetAudioLoading={isFetchingFrSentAudio}
              onGetAudio={() => {
                notify.show(`This may take a while, please wait...`, "warning")
                setFetchSentFrAudioEnabled(true)
              }}
              audioTime={frSentAudioTotalTime}
              audiosUrls={frSentAudioUrls}
            />
          }
          {/* <div className="flex gap-6 mt-2 mb-4 float-right">
            <span className="text-green-600 float-right">
              {Math.floor(frSentAudioTotalTime)}s
            </span>
          </div> */}
          {
            selectedSentLangView === 'it' && <LangInput
              isFetching={isFetchingItSent}
              textArea
              placeholder={ptSent ? "..." : "Ein erstaunliches Produkt..."}
              buttonTitle="Italian"
              value={itSent}
              // disabled
              disabledButton
              disabledAudioButton={
                !selectedVoice ||
                !ptSent ||
                !isItSentValid ||
                fetchSentItAudioEnabled ||
                isFetchingItSentAudio ||
                isSentTranslationLoading
              }
              hasAudio
              onChange={onChangeItSent}
              showGetAudio
              isGetAudioLoading={isFetchingItSentAudio}
              onGetAudio={() => {
                notify.show(`This may take a while, please wait...`, "warning")
                setFetchSentItAudioEnabled(true)
              }}
              audioTime={itSentAudioTotalTime}
              audiosUrls={itSentAudioUrls}
            />
          }
          {/* <div className="flex gap-6 mt-2 mb-4 float-right">
            <span className="text-green-600 float-right">
              {Math.floor(itSentAudioTotalTime)}s
            </span>
          </div> */}
        </Label>
      </div>

      {/* <div className="px-4 py-3 mb-8 bg-white rounded-lg shadow-md dark:bg-gray-800">
        <Label
          onClick={(e) => {
            e.preventDefault()
          }}
        >
          <span className={`${isScenesValid ? "" : "text-red-500"}`}>
            Product Videos
          </span>
          <div className="mt-2 mb-4 w-full flex items-center justify-center">
            <FileUploader
              disabled={uploading}
              className="disabled:opacity-30"
              multiple={true}
              handleChange={handleClipsInput}
              name="clips"
              types={fileTypes}
              required
            />
          </div>
          {!!Object.keys(clips).length && (
            <div
              className="
              mt-4
              gap-6
              mb-8
              items-center
              justify-between
              align-middle
              border-2
              border-dashed
              border-[#0658c2]
              border-opacity-30
              min-h-64
              p-4
              rounded-xl
              grid"
            >
              <VideosRenderer
                setter={setClips}
                isLoadingClips={uploading}
                clips={clips}
                disableLinking
                disableLooping
                onDeleteClip={(key) => {
                  Object.keys(scenes).map((sceneKey) => {
                    if (sceneKey.startsWith(key)) {
                      const deleteCurrentScene = generateOnDelete(
                        setScenes,
                        sceneKey
                      )
                      deleteCurrentScene()
                    }
                  })
                }}
              />
            </div>
          )}
        </Label>

        {!!Object.keys(scenes).length && (
          <Label className="mt-4">
            <div className="w-full flex flex-row justify-between">
              <span className={`${isScenesValid ? "" : "text-red-500"}`}>
                Clips
              </span>
            </div>
            <div
              className="
              mt-4
              gap-6
              mb-4
              items-center
              justify-between
              align-middle
              border-2
              border-dashed
            border-[#0658c2]
              border-opacity-30
              min-h-64
              p-4
              rounded-xl
              grid"
            >
              <VideosRenderer
                setter={setScenes}
                isLoadingClips={uploading}
                clips={scenes}
                disableLoopPlus={!!hasMinimumVideoTime}
              />
            </div>
            <div className="mb-4">
              <span
                className={`${
                  hasMinimumVideoTime ? "text-green-600" : "text-red-500"
                }`}
              >
                Total time: ~{Math.floor(totalTime)}s (Required: {minVideoTime}
                s)
              </span>
            </div>
          </Label>
        )}
      </div> */}
      <AddFormVideoContainer
        isScenesValid={isScenesValid}
        isLoading={isGetCodeLoading}
        minVideoTime={minVideoTime}
        setClipsCb={setClips}
        setScenesCb={setScenes}
      />

      <div className="px-4 py-3 mb-8 bg-white rounded-lg shadow-md dark:bg-gray-800">
        <Label>
          <span>Let's do it!</span>
          <div className="flex w-full mt-2">
            <button
              disabled={!isAllValid}
              onClick={handleSubmitData}
              className="disabled:pointer-events-none disabled:opacity-50 inset-y-0 px-4 text-sm font-medium leading-3 text-white transition-colors duration-150 bg-purple-600 border border-transparent rounded-l-md active:bg-purple-600 hover:bg-purple-700 focus:outline-none focus:shadow-outline-purple dark:focus:shadow-outline-gray"
            >
              <div className="my-2 mx-4">Create Video!</div>
            </button>
            {/* <TagsInput
              disabled={isTagsValid}
              value={tags}
              onChange={(t) => {
                // @ts-ignore
                setTags(
                  // @ts-ignore
                  t.reduce(
                    (p, t) =>
                      // @ts-ignore
                      [
                        ...p,
                        ...t.split(",").map((sub_t) => sub_t.trim()),
                      ].slice(0, 4),
                    []
                  )
                )
              }}
              classNames={{
                input:
                  "block rounded-l-none rounded-r-md h-6 w-full mt-1 text-sm dark:text-gray-300 dark:border-gray-600 dark:bg-gray-700 focus:border-purple-400 focus:outline-none focus:shadow-outline-purple dark:focus:shadow-outline-gray form-input",
                tag: "",
              }}
              name="tags"
              placeHolder="hashtag 1, hashtag 2, hashtag 3..."
            /> */}
          </div>
        </Label>
      </div>
    </>
  )
}

export default AddForm
