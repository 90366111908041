import React, { useEffect, useState } from "react"
import { useIsMounted } from "../hooks/useIsMounted"
import { Label, Input, Button } from "@roketid/windmill-react-ui"
import { useNavigate } from "react-router-dom"
import { useLoginMutation } from "../mutations/useLoginMutation"

export default function Login() {
  const isMounted = useIsMounted()
  const navigate = useNavigate()
  const loginMutation = useLoginMutation()

  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  })

  const handleInputChange = (e) => {
    const { name, value } = e.target
    setFormData({
      ...formData,
      [name]: value,
    })
  }

  const handleSubmit = async (e) => {
    setLoading(true)
    e.preventDefault()
    try {
      await loginMutation.mutateAsync(formData)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.error(error)
    }
  }

  useEffect(() => {
    const isAuthenticated = !!localStorage.getItem("token")
    if (!loading && isMounted() && isAuthenticated) {
      navigate("/dashboard", { replace: true })
    }
  }, [isMounted, loading, navigate])

  return (
    <div className="flex w-11/12 md:w-1/2 max-w-2xl items-center p-6 pb-12 bg-gray-50 dark:bg-gray-900 absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
      <div className="flex-1 h-full overflow-hidden bg-white rounded-lg shadow-xl dark:bg-gray-800">
        <div className="flex flex-col overflow-y-auto md:flex-row w-full">
          <main className="flex items-center justify-center p-6 pb-10 w-full">
            <form onSubmit={handleSubmit} className="w-full" autoComplete="on">
              <div className="w-full">
                <h1 className="mb-4 text-xl font-semibold text-gray-700 dark:text-gray-200">
                  Login
                </h1>
                <Label>
                  <span>Email</span>
                  <Input
                    crossOrigin="use-credentials"
                    className="mt-1"
                    type="email"
                    id="email"
                    name="email"
                    placeholder="john@doe.com"
                    onChange={handleInputChange}
                  />
                </Label>

                <Label className="mt-4">
                  <span>Password</span>
                  <Input
                    crossOrigin="use-credentials"
                    className="mt-1"
                    type="password"
                    placeholder="***************"
                    id="password"
                    name="password"
                    onChange={handleInputChange}
                  />
                </Label>

                <Button
                  disabled={loading}
                  className="mt-4 disabled:pointer-events-none disabled:opacity-50"
                  block
                  type="submit"
                >
                  Log in
                </Button>
              </div>
            </form>
          </main>
        </div>
      </div>
    </div>
  )
}
