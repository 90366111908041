import { useCallback } from "react"
import { useQuery } from "react-query"
import axios from "axios"
import { API } from "../contants"
import { notify } from "react-notify-toast"

export default function useResetVideo(_id, code, lang, voice) {
  const useResetVideoCallback = useCallback(async () => {
    if (!lang || !code || !voice) return
    try {
      const options = {
        method: "POST",
        url: API + "/cdn/v1/products/reset",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        crossDomain: true,
        data: {
          code,
          lang,
          voice,
        },
      }

      const response = await axios.request(options)

      return response.data
    } catch (error) {
      const error_msg = "Error while reseting video"
      notify.show(error_msg, "error")
      throw new Error(error_msg)
    }
  }, [code, lang, voice])

  return useQuery([`${_id}_reset_video`], useResetVideoCallback, {
    refetchOnWindowFocus: false,
    enabled: false,
  })
}
