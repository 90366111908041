import React, { useEffect, useMemo, useState } from "react"
import ThemedSuspense from "../components/ThemedSuspense"
import Header from "../components/Header"
import useGetUser from "../queries/useGetUser"
import { notify } from "react-notify-toast"
import delay from "../functions/delay"

export default function UserWrapper({
  children,
  hiddenHeader = false,
  refetchUserOnWindowFocus = true,
}) {
  const [hasSaidHello, setHasSaidHello] = useState(false)
  const {
    isLoading,
    data: profile,
    refetch,
  } = useGetUser(refetchUserOnWindowFocus)

  useEffect(() => {
    if (profile && !hasSaidHello && profile.name) {
      setHasSaidHello(true)
      notify.show(`Hello ${profile.name}`, "success")
      delay(500).then(() => refetch())
    }
  }, [profile, hasSaidHello, hiddenHeader, isLoading, refetch])

  const renderHeader = useMemo(
    () =>
      hiddenHeader ? null : (
        <Header loadingAvatar={isLoading} avatar={profile && profile.pic} />
      ),
    [hiddenHeader, isLoading, profile]
  )
  return (
    <>
      {isLoading && <ThemedSuspense />}
      {renderHeader}
      {children}
    </>
  )
}
