import { useState, useRef, useEffect } from 'react'
import H5AudioPlayer from "react-h5-audio-player"
import { notify } from "react-notify-toast"
import { AudioPlayIcon, AudioPauseIcon } from '../icons'
import useStickyState from '../hooks/useStickyState'
import { useCurrentAudio } from '../providers/AudioContext'

export const AudioPlayer = ({ url }) => {
  const [status, setStatus] = useState({
    isPlaying: false,
    isLoop: false,
    isLoaded: false,
    error: false,
  });
  const [currentPlayingUrl, setCurrentPlayingUrl, currentVolume, setCurrentVolume] = useCurrentAudio()
  const audioRef = useRef(null);

  const toggleAudio = () => {
    status.isLoaded
      ? status.isPlaying
        ? audioRef.current.audio.current.pause()
        : audioRef.current.audio.current.play()
      : notify.show(`Audio has not loaded yet...`, "error");
  }

  useEffect(() => {
    if (status.isPlaying && currentPlayingUrl !== url) {
      toggleAudio()
      setStatus({ ...status, isPlaying: false })
    }
  }, [toggleAudio, status, currentPlayingUrl, url])

  useEffect(() => {
    if (audioRef.current.audio.current) {
      audioRef.current.audio.current.volume = currentVolume
    }
  }, [currentVolume])

  return <div className="flex w-96 justify-center content-center items-center">
    <H5AudioPlayer
      className={`w-60`}
      src={url}
      ref={audioRef}
      showJumpControls={false}
      showSkipControls={false}
      showDownloadProgress={false}
      showFilledProgress={false}
      showFilledVolume={true}
      layout="horizontal"
      onCanPlayThrough={() => {
        setStatus({ ...status, isLoaded: true })
      }}
      onLoad={() => {
        setStatus({ ...status, isLoaded: true })
      }}
      onPlay={() => {
        setStatus({ ...status, isPlaying: true })
      }}
      onPause={() => {
        setStatus({ ...status, isPlaying: false })
      }}
      onError={() => {
        setStatus({ ...status, error: true })
      }}
      onVolumeChange={(v) => {
        setCurrentVolume(v.target.volume)
      }}
    />
    {
      status.isPlaying
        ? <AudioPauseIcon onClick={toggleAudio} className="opacity-50 hover:opacity-90 cursor-pointer dark:invert" width="20px" height="20px" />
        : <AudioPlayIcon onClick={() => {
          setCurrentPlayingUrl(url)
          toggleAudio()
        }} className="opacity-50 hover:opacity-90 cursor-pointer dark:invert" width="20px" height="20px" />
    }
  </div>
}
