import React from "react"

export const TrashIcon = (props) => {
  return (
    <svg {...props} fill="currentColor" viewBox="0 0 20 20">
      <path
        fillRule="evenodd"
        d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
        clipRule="evenodd"
      ></path>
    </svg>
  )
}

export const SunIcon = (props) => {
  return (
    <svg {...props} fill="currentColor" viewBox="0 0 20 20">
      <path
        fillRule="evenodd"
        d="M10 2a1 1 0 011 1v1a1 1 0 11-2 0V3a1 1 0 011-1zm4 8a4 4 0 11-8 0 4 4 0 018 0zm-.464 4.95l.707.707a1 1 0 001.414-1.414l-.707-.707a1 1 0 00-1.414 1.414zm2.12-10.607a1 1 0 010 1.414l-.706.707a1 1 0 11-1.414-1.414l.707-.707a1 1 0 011.414 0zM17 11a1 1 0 100-2h-1a1 1 0 100 2h1zm-7 4a1 1 0 011 1v1a1 1 0 11-2 0v-1a1 1 0 011-1zM5.05 6.464A1 1 0 106.465 5.05l-.708-.707a1 1 0 00-1.414 1.414l.707.707zm1.414 8.486l-.707.707a1 1 0 01-1.414-1.414l.707-.707a1 1 0 011.414 1.414zM4 11a1 1 0 100-2H3a1 1 0 000 2h1z"
        clipRule="evenodd"
      ></path>
    </svg>
  )
}

export const MoonIcon = (props) => {
  return (
    <svg {...props} fill="currentColor" viewBox="0 0 20 20">
      <path d="M17.293 13.293A8 8 0 016.707 2.707a8.001 8.001 0 1010.586 10.586z"></path>
    </svg>
  )
}

export const OutlineLogoutIcon = (props) => {
  return (
    <svg
      {...props}
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path d="M11 16l-4-4m0 0l4-4m-4 4h14m-5 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h7a3 3 0 013 3v1"></path>
    </svg>
  )
}

export const LinkIcon = (props) => {
  return (
    <svg {...props} viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
      <g
        transform="matrix(0.081324003636837, 0, 0, -0.07281000167131424, 49.23785781860351, 467.9364929199219)"
        fill="currentColor"
        stroke="none"
      >
        <path
          d="M600 3745 c-297 -65 -524 -293 -584 -587 -24 -114 -24 -1082 0 -1195 42 -203 165 -380 339 -488 172 -107 224 -115 740 -115 l415 0 -71 88 c-105 129 -180 293 -223 485 l-6 27 -305 0 -305 0 0 600 0 600 1210 0 1210 0 0 -300 0 -300 300 0 300 0 0 260 c0 287 -9 361 -59 481 -82 196 -251 350 -461 422 l-95 32 -1170 2 c-976 1 -1181 -1 -1235 -12z"
          style={{
            fillRule: "nonzero",
            fill: "currentColor",
            paintOrder: "stroke markers",
            stroke: "white",
            strokeWidth: "200px",
          }}
        />
        <path
          d="M3674 3681 c124 -153 180 -273 230 -493 l6 -28 305 0 305 0 0 -600 0 -600 -1210 0 -1210 0 0 450 0 450 -300 0 -300 0 0 -410 c0 -449 5 -501 59 -630 73 -174 227 -328 401 -401 144 -60 112 -59 1350 -59 995 0 1140 2 1208 16 278 57 494 260 579 543 15 49 17 127 20 605 4 478 2 560 -12 630 -61 299 -289 529 -587 590 -63 13 -153 16 -493 16 l-416 0 65 -79z"
          style={{
            fillRule: "nonzero",
            fill: "currentColor",
            paintOrder: "stroke markers",
            stroke: "white",
            strokeWidth: "200px",
          }}
        />
      </g>
    </svg>
  )
}

export const InterrogationIcon = (props) => {
  return (
    <svg
      {...props}
      fill="currentColor"
      version="1.1"
      viewBox="0 0 367.011 367.01"
    >
      <g>
        <g>
          <path
            d="M365.221,329.641L190.943,27.788c-1.542-2.674-4.395-4.318-7.479-4.318c-3.084,0-5.938,1.645-7.48,4.318L1.157,330.584
			c-1.543,2.674-1.543,5.965,0,8.639c1.542,2.674,4.395,4.318,7.48,4.318h349.65c0.028,0,0.057,0,0.086,0
			c4.77,0,8.638-3.863,8.638-8.639C367.011,332.92,366.342,331.1,365.221,329.641z M23.599,326.266L183.464,49.381l159.864,276.885
			H23.599z"
          />
          <path
            d="M174.826,136.801v123.893c0,4.773,3.867,8.638,8.638,8.638c4.77,0,8.637-3.863,8.637-8.638V136.801
			c0-4.766-3.867-8.637-8.637-8.637C178.693,128.165,174.826,132.036,174.826,136.801z"
          />
          <path
            d="M183.464,279.393c-5.922,0-10.725,4.8-10.725,10.722s4.803,10.729,10.725,10.729c5.921,0,10.725-4.809,10.725-10.729
			C194.189,284.193,189.386,279.393,183.464,279.393z"
          />
        </g>
      </g>
    </svg>
  )
}

export const AudioIcon = (props) => {
  return (
    <svg
      {...props}
      fill="currentColor"
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      width="800px"
      height="800px"
      viewBox="0 0 522.234 522.234"
    >
      <g>
        <g>
          <g>
            <path
              d="M346.35,41.163c-10.855-10.367-25.076-16.078-40.045-16.078c-10.832,0-21.445,3.063-30.689,8.857l-159.161,99.761H58.354
         c-31.9,0-57.854,25.952-57.854,57.853v138.918c0,31.9,25.953,57.854,57.854,57.854h57.773l159.487,99.965
         c9.244,5.795,19.857,8.857,30.691,8.857c14.969,0,29.189-5.71,40.047-16.078c5.543-5.293,9.908-11.525,12.979-18.523
         c3.227-7.353,4.861-15.184,4.861-23.275V261.22v-0.205V82.963c0-8.094-1.635-15.925-4.861-23.278
         C356.26,52.689,351.893,46.457,346.35,41.163z M321.354,261.015v0.205v178.052c0,8.803-7.227,15.037-15.049,15.037
         c-2.664,0-5.398-0.724-7.939-2.316L137.222,350.989c-5.736-3.595-12.368-5.502-19.138-5.502h-59.73
         c-8.292,0-15.014-6.722-15.014-15.014V191.556c0-8.291,6.722-15.013,15.014-15.013h60.059c6.77,0,13.4-1.907,19.137-5.502
         L298.365,70.242c2.541-1.593,5.273-2.316,7.939-2.316c7.822,0,15.049,6.236,15.049,15.038V261.015z"
            />
            <path
              d="M306.305,497.649c-10.929,0-21.634-3.089-30.957-8.934l-159.365-99.889H58.354C26.177,388.827,0,362.649,0,330.474
         V191.556c0-32.176,26.177-58.353,58.354-58.353h57.958L275.35,33.519c9.325-5.844,20.029-8.934,30.955-8.934
         c15.096,0,29.44,5.759,40.391,16.216c5.591,5.34,9.995,11.625,13.093,18.683c3.254,7.415,4.903,15.314,4.903,23.479v356.309
         c0,8.163-1.649,16.062-4.903,23.477c-3.099,7.062-7.503,13.348-13.091,18.684C335.746,491.891,321.401,497.649,306.305,497.649z
          M58.354,134.203C26.729,134.203,1,159.931,1,191.556v138.918c0,31.625,25.729,57.354,57.354,57.354h57.917l0.122,0.076
         l159.487,99.965c9.164,5.745,19.685,8.781,30.426,8.781c14.838,0,28.938-5.661,39.701-15.939
         c5.493-5.245,9.821-11.423,12.866-18.363c3.198-7.287,4.819-15.05,4.819-23.074V82.963c0-8.025-1.621-15.79-4.819-23.077
         c-3.044-6.937-7.373-13.114-12.868-18.362c-10.763-10.279-24.861-15.939-39.699-15.939c-10.738,0-21.259,3.037-30.424,8.781
         l-159.283,99.837H58.354z M306.305,454.809c-2.87,0-5.708-0.827-8.205-2.393L136.956,351.413
         c-5.664-3.55-12.189-5.426-18.872-5.426h-59.73c-8.554,0-15.514-6.959-15.514-15.514V191.556c0-8.554,6.959-15.513,15.514-15.513
         h60.059c6.682,0,13.207-1.876,18.871-5.426L298.1,69.818c2.497-1.565,5.335-2.393,8.205-2.393c8.573,0,15.549,6.97,15.549,15.538
         v356.308C321.854,447.839,314.878,454.809,306.305,454.809z M58.354,177.043c-8.003,0-14.514,6.51-14.514,14.513v138.918
         c0,8.003,6.511,14.514,14.514,14.514h59.73c6.871,0,13.58,1.929,19.403,5.578l161.144,101.003c2.338,1.466,4.991,2.24,7.674,2.24
         c8.022,0,14.549-6.521,14.549-14.537V82.963c0-8.016-6.526-14.538-14.549-14.538c-2.683,0-5.336,0.774-7.674,2.24
         L137.814,171.465c-5.824,3.649-12.533,5.578-19.402,5.578H58.354z"
            />
          </g>
          <g>
            <path
              d="M424.273,156.536c-5.266-10.594-18.125-14.911-28.715-9.646c-10.594,5.266-14.912,18.123-9.646,28.716
         c12.426,24.995,18.992,54.604,18.992,85.626c0,31.506-6.754,61.487-19.533,86.705c-5.348,10.553-1.129,23.442,9.424,28.79
         c3.104,1.572,6.408,2.317,9.664,2.317c7.816,0,15.35-4.294,19.125-11.742c15.807-31.191,24.16-67.869,24.16-106.07
         C447.746,223.628,439.629,187.424,424.273,156.536z"
            />
            <path
              d="M404.459,379.545c-3.456,0-6.784-0.798-9.89-2.371c-10.782-5.464-15.108-18.681-9.645-29.462
         c12.744-25.147,19.479-55.052,19.479-86.479c0-30.948-6.549-60.48-18.939-85.404c-2.606-5.243-3.016-11.188-1.15-16.738
         c1.864-5.55,5.778-10.042,11.021-12.648c3.064-1.523,6.341-2.296,9.739-2.296c8.388,0,15.916,4.662,19.646,12.167
         c15.391,30.959,23.524,67.239,23.522,104.919c0,38.28-8.373,75.037-24.214,106.296
         C420.273,374.94,412.773,379.545,404.459,379.545z M405.075,145.146c-3.242,0-6.369,0.737-9.294,2.191
         c-5.004,2.487-8.74,6.774-10.52,12.071c-1.779,5.297-1.39,10.97,1.098,15.974c12.459,25.062,19.045,54.748,19.045,85.849
         c0,31.584-6.773,61.645-19.587,86.931c-5.215,10.29-1.086,22.904,9.203,28.118c2.965,1.502,6.141,2.264,9.438,2.264
         c7.936,0,15.094-4.395,18.679-11.468c15.771-31.12,24.106-67.721,24.106-105.845c0.002-37.526-8.096-73.652-23.418-104.474
         C420.266,149.596,413.081,145.146,405.075,145.146z"
            />
          </g>
          <g>
            <path
              d="M456.547,88.245c-10.594,5.266-14.912,18.122-9.646,28.716c20.932,42.105,31.994,91.864,31.994,143.897
         c0,52.847-11.381,103.237-32.912,145.727c-5.348,10.552-1.129,23.441,9.424,28.788c3.104,1.573,6.408,2.318,9.666,2.318
         c7.814,0,15.35-4.294,19.123-11.743c24.559-48.462,37.539-105.549,37.539-165.09c0-58.615-12.611-114.968-36.473-162.968
         C479.996,87.297,467.141,82.977,456.547,88.245z"
            />
            <path
              d="M465.072,438.19c-3.458,0-6.787-0.798-9.893-2.372c-5.223-2.646-9.102-7.168-10.923-12.732s-1.367-11.506,1.279-16.728
         c21.496-42.42,32.858-92.733,32.858-145.501c0-51.958-11.045-101.64-31.941-143.674c-5.381-10.824-0.952-24.006,9.871-29.386
         c3.065-1.524,6.343-2.297,9.742-2.297c8.386,0,15.912,4.663,19.643,12.167c23.896,48.067,36.525,104.498,36.525,163.19
         c0,59.619-12.999,116.785-37.593,165.315C480.887,433.586,473.388,438.19,465.072,438.19z M466.066,86.5
         c-3.243,0-6.371,0.738-9.297,2.193c-5.004,2.487-8.74,6.774-10.52,12.071s-1.389,10.97,1.098,15.974
         c20.966,42.172,32.047,92.008,32.047,144.12c0,52.924-11.399,103.394-32.966,145.952c-2.526,4.984-2.96,10.654-1.222,15.965
         s5.44,9.626,10.425,12.151c2.965,1.503,6.141,2.265,9.44,2.265c7.937,0,15.094-4.395,18.677-11.469
         c24.523-48.392,37.485-105.401,37.485-164.864c0-58.54-12.594-114.816-36.42-162.745C481.253,90.949,474.069,86.5,466.066,86.5z"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

export const AudioPlayIcon = (props = {}) => <svg
  {...props}
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  x="0px"
  y="0px"
  viewBox="0 0 256 256"
  enableBackground="new 0 0 256 256"
  xml="preserve">
  <g>
    <g>
      <path
        fill="#000000"
        d="M116.2,81.3c-1.8-1.5-4.5-0.2-4.5,2.1v89.3c0,2.3,2.7,3.6,4.5,2.1l53-44.6c1.3-1.1,1.3-3.1,0-4.2L116.2,81.3z"
      />
      <path
        fill="#000000"
        d="M128,10C62.8,10,10,62.8,10,128c0,65.2,52.8,118,118,118c65.2,0,118-52.8,118-118C246,62.8,193.2,10,128,10z M128,229.1c-55.9,0-101.1-45.3-101.1-101.1C26.9,72.1,72.1,26.9,128,26.9c55.9,0,101.1,45.3,101.1,101.1C229.1,183.9,183.9,229.1,128,229.1z"
      />
    </g>
  </g>
</svg>

export const AudioPauseIcon = (props = {}) => <svg
  {...props}
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  x="0px"
  y="0px"
  viewBox="0 0 256 256"
  enableBackground="new 0 0 256 256"
  xml="preserve">
  <g>
    <g>
      <path
        fill="#000000"
        d="M128,25.7c56.6,0,102.3,45.6,102.3,102.3c0,56.6-45.6,102.3-102.3,102.3c-56.6,0-102.3-45.6-102.3-102.3C25.7,71.4,71.4,25.7,128,25.7 M128,10C62.7,10,10,62.7,10,128s52.7,118,118,118s118-52.7,118-118S193.3,10,128,10L128,10z" />
      <path
        fill="#000000"
        d="M97.3,81.6H113v96H97.3V81.6L97.3,81.6z" />
      <path
        fill="#000000"
        d="M143,81.6h15.7v96H143V81.6L143,81.6z" />
    </g>
  </g>
</svg>
