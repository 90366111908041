import { useCallback } from "react"
import { useQuery } from "react-query"
import axios from "axios"
import { API } from "../contants"
import { notify } from "react-notify-toast"
import clearLocalStorageExceptOneItem from "../functions/clearLocalStorageExceptOneItem"

export default function useGetCode() {
  // { isLoading, isError, data, error, refetch }
  const useGetCodeCallback = useCallback(async () => {
    const token = localStorage.getItem("token")

    if (!token) return

    const stored_code = localStorage.getItem("code")
    if (stored_code) return stored_code

    clearLocalStorageExceptOneItem("token")

    try {
      const options = {
        method: "GET",
        url: API + `/api/v1/code`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        crossDomain: true,
      }

      const response = await axios.request(options)
      const code = response.data.code

      code && localStorage.setItem("code", code)
      return code
    } catch (error) {
      const error_msg = "Error while getting code"
      notify.show(error_msg, "error")
      throw new Error(error_msg)
    }
  }, [])

  return useQuery(["get_code"], useGetCodeCallback, {
    refetchOnWindowFocus: false,
  })
}
