import React, { useState, useEffect } from "react"

const useGetTotalTime = (scenes) => {
  const totalTimeObj = Object.keys(scenes)
    .map((key) => scenes[key])
    .reduce(
      (prev, { start, end, loop, merge_with_next }) => {
        return {
          total: merge_with_next
            ? prev.total
            : prev.total +
              (end - start + prev.sum_next) * (loop ? loop + 1 : 1),
          sum_next: merge_with_next ? end - start + prev.sum_next : 0,
        }
      },
      {
        total: 0,
        prev: 0,
        sum_next: 0,
      }
    )

  return { totalTime: totalTimeObj.total }
}

export default useGetTotalTime
