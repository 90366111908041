export const generateFileHash = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()

    reader.onloadend = () => {
      const buffer = reader.result
      window.crypto.subtle
        // @ts-ignore
        .digest("SHA-256", buffer)
        .then((hashArray) => {
          const hashArrayBytes = Array.from(new Uint8Array(hashArray))
          const hashHex = hashArrayBytes
            .map((b) => b.toString(16).padStart(2, "0"))
            .join("")
          resolve(hashHex)
        })
        .catch((error) => {
          reject(error)
        })
    }

    reader.onerror = () => {
      reject(new Error("Error reading the file"))
    }

    reader.readAsArrayBuffer(file)
  })
}
