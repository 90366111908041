import React from "react"
import ReactDOM from "react-dom/client"
import { App } from "./App"
import reportWebVitals from "./reportWebVitals"
import { Windmill } from "@roketid/windmill-react-ui"
import { QueryClient, QueryClientProvider } from "react-query"
import Notifications from "react-notify-toast"

import "./index.scss"

const queryClient = new QueryClient()
const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
  <React.StrictMode>
    <Notifications />
    <Windmill usePreferences={true}>
      <QueryClientProvider client={queryClient}>
        <App />
      </QueryClientProvider>
    </Windmill>
  </React.StrictMode>
)

reportWebVitals()
