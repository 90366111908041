import { useCallback } from "react"
import { useQuery } from "react-query"
import axios from "axios"
import { API } from "../contants"
import { notify } from "react-notify-toast"

export default function useGetSounds(subfolder, cb) {
  // { isLoading, isError, data, error, refetch }
  const useGetSoundsCallback = useCallback(async () => {
    try {
      const options = {
        method: "GET",
        url: API + `/cdn/v1/audios/${subfolder}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        crossDomain: true,
      }

      const response = await axios.request(options)
      const files = response.data.files.map((file) => {
        return {
          name: file.includes(".")
            ? file.split(".").slice(0, -1).join(".")
            : file,
          url: API + `/cdn/v1/sound/${subfolder}/${file}`,
        }
      })
      cb && cb(files)
      return files
    } catch (error) {
      const error_msg = "Error while getting sound"
      notify.show(error_msg, "error")
      throw new Error()
    }
  }, [cb, subfolder])

  return useQuery([`${subfolder}_get_sound`], useGetSoundsCallback, {
    refetchOnWindowFocus: false,
    enabled: false,
  })
}
