import { useCallback } from "react"
import { useQuery } from "react-query"
import axios from "axios"
import { API } from "../contants"
import { notify } from "react-notify-toast"

export default function useApprove(code, lang) {
  // { isLoading, isError, data, error, refetch }
  const useApproveCallback = useCallback(async () => {
    if (!code || !lang) return
    try {
      const options = {
        method: "POST",
        url: API + `/cdn/v3/approve/${code}/${lang}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        crossDomain: true,
      }

      const response = await axios.request(options)
      const message = response.data.message

      return message
    } catch (error) {
      const error_msg = "Error while approving video"
      notify.show(error_msg, "error")
      throw new Error()
    }
  }, [code, lang])

  return useQuery([`${code}_${lang}_approve_video`], useApproveCallback, {
    refetchOnWindowFocus: false,
    enabled: false,
  })
}
