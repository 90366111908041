import { useMemo } from "react"
import { useCheckSentences } from "./useCheckSentences"
import useGetTotalTime from "./useGetTotalTime"

const isValidUrl = (urlString) => {
  var urlPattern = new RegExp(
    "^(https?:\\/\\/)?" + // validate protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // validate domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // validate OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // validate port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // validate query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ) // validate fragment locator
  return !!urlPattern.test(urlString)
}

const hasKeys = (ojb) => !!Object.keys(ojb).length

export const useValidateData = (
  code,
  clips,
  scenes,
  ptTitle,
  ptSent,
  enTitle,
  enSent,
  esTitle,
  esSent,
  frTitle,
  frSent,
  deTitle,
  deSent,
  aliLink,
  minSceneTime,
  selectedMusic,
  selectedVoice
) => {
  const { optional, required, bigSentenceFound } = useCheckSentences(ptSent)

  const { totalTime } = useGetTotalTime(scenes)

  const isCodeValid = !!code
  const isClipsValid = hasKeys(clips)
  const isScenesValid = hasKeys(scenes) && totalTime > minSceneTime
  const isPtTitleValid = !!ptTitle
  const isPtSentValid = optional.valid && required.valid && !bigSentenceFound
  const isEnTitleValid = !!enTitle
  const isEnSentValid = !!enSent
  const isEsTitleValid = !!esTitle
  const isEsSentValid = !!esSent
  const isFrTitleValid = !!frTitle
  const isFrSentValid = !!frSent
  const isDeTitleValid = !!deTitle
  const isItSentValid = !!deSent
  const isAliLinkValid = isValidUrl(aliLink)

  const isAllValid =
    isCodeValid &&
    isClipsValid &&
    isScenesValid &&
    isPtTitleValid &&
    isPtSentValid &&
    isEnTitleValid &&
    isEnSentValid &&
    isEsTitleValid &&
    isEsSentValid &&
    isFrTitleValid &&
    isFrSentValid &&
    isDeTitleValid &&
    isItSentValid &&
    isAliLinkValid &&
    totalTime > minSceneTime &&
    selectedMusic &&
    selectedVoice

  const isAllTitlesValid =
    isPtTitleValid &&
    isEnTitleValid &&
    isEsTitleValid &&
    isFrTitleValid &&
    isDeTitleValid

  const isAllSentValid =
    isPtSentValid &&
    isEnSentValid &&
    isEsSentValid &&
    isFrSentValid &&
    isItSentValid

  return useMemo(
    () => ({
      isCodeValid,
      isClipsValid,
      isScenesValid,
      isPtTitleValid,
      isPtSentValid,
      isEnTitleValid,
      isEnSentValid,
      isEsTitleValid,
      isEsSentValid,
      isFrTitleValid,
      isFrSentValid,
      isDeTitleValid,
      isItSentValid,
      isAliLinkValid,
      isAllValid,
      isAllTitlesValid,
      isAllSentValid,
    }),
    [
      isAliLinkValid,
      isAllSentValid,
      isAllTitlesValid,
      isAllValid,
      isClipsValid,
      isCodeValid,
      isItSentValid,
      isDeTitleValid,
      isEnSentValid,
      isEnTitleValid,
      isEsSentValid,
      isEsTitleValid,
      isFrSentValid,
      isFrTitleValid,
      isPtSentValid,
      isPtTitleValid,
      isScenesValid,
    ]
  )
}
